import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { DeviceUUID } from "device-uuid";
import { useSwipeable, config } from "react-swipeable";
import InfiniteScroll from "react-infinite-scroll-component";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { connect, useDispatch, useSelector } from "react-redux";
import { useClearCache } from "react-clear-cache";
import {
  getTableDetail,
  emptyTableDetail,
  getTableDetailByFilter,
  getSelectedItemDetail,
  updateSearchText,
  emptyChangeTableAlreadyInuse,
  changeTableNotFound,
  emptyEditedItemDetail,
  updateRatingDetail,
  checkOpenOrder,
  dismissItemAvailableModal,
  emptyUserDetail,
  toggleXgatePointsPopup,
  changeRedirectHome,
  changePaymentDetail,
  orderPlaceDetail,
  showNotificationWithTimeout,
  quantityCheckedClose,
  quantityCheckedDetails,
  quantityModalClose,
  updateItemCartDetail,
  removeItemCartDetail,
  addSameProductCheck,
  setActiveItemData,
  emptyComoDetails,
  removeCombineOrder,
  saveServerOrderId,
  resetOrderData,
  setOrderData,
  saveCartTemporary,
  addItemCartDetail,
  getOpenOrderData,
  emptyCartDetail,
  redirectTable,
  combineOrderStatus,
  emptyServerDetail,
  tableInitialState,
  startNewSession,
  remove_86_ItemCartDetail,
  updateTrendingPopularTags,
  getOrderHistoryDetail,
  checkOrderrStatus,
  updateCallServer,
  acknowledgeCallServerData,
} from "../../Redux/actions";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Cart from "./Cart";
import UnableToLoadData from "../Components/UnableToLoadData";
import PleaseWait from "../Components/PleaseWait";
import ImageTag from "../Components/ImageTag";
import RatingInput from "../Components/RatingInput";
import {
  pluckArray,
  getSessionStorageOrDefault,
  checkItemAvailable,
  truncateString,
  getCartNewItemCount,
  deepCopy,
  checkVoucherAvailable,
  checkSubCatAvailable,
  checkArray,
  checkExpiredSession,
  checkInactiveSession,
  // getCartBody,
  checkNull,
  parseRound,
  percentage,
  checkIsNum,
  scrollToDiv,
  removeTicketPayload,
  filter_CombineOrder_Data,
  updateCartTemporaryData,
  getCartFiredItemCount,
  checkTrendingAndPopularApplied,
  customFilterArray,
  isTokenExpired,
  checkItemAvailableHistory,
  checkItemAvailableHistroy,
  getDeviceId,
} from "../../helpers";
import {
  poweredLogo1PngIcon,
  filter as filterImg,
  search_icon as searchImg,
  close_dark,
  reorder,
  ordrrLogoNew,
  searchIcon,
  serverDetailsLogo,
  minusIcon,
  plusIcon,
  removeIcon,
  logout,
  user,
  userIcon,
  cart,
  refresh,
  shoppingcart,
  loyaltyHeart,
  downDown,
  search,
  fireFlame,
  handWave,
  wave,
  trending,
  downUp,
  cross_icon,
  close,
  cricle_cross,
  close_black,
  waveGif,
} from "../../Assets";
import { Motion, spring, presets } from "react-motion";
import AccountPopup from "../Components/AccountPopup";
import Modal from "../Components/Modal";
import XgatePointsPopup from "../Components/XgatePointsPopup";
import {
  APPLY_VOUCHER_DETAIL_RECEIVED,
  TOGGLE_CART_PAGE,
} from "../../Redux/actions/types";
import { StyledSVG } from "../../Utils/style";
import { getCartBody, getQRCartBody } from "../../Utils/orderHelper";
import AppHeader from "../Components/AppHeader";
import CustomButton from "../Components/CustomButton";
import SearchableSelect from "../Components/SearchableSelect";
import CustomDropDown from "../../Component/molecules/CustomDropDown";
import { Carousel } from "react-responsive-carousel";
import AddItem from "./AddItem";
import BundleAddItem from "./BundleAddItem";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { firebaseDB, push, ref } from "../../Utils/firebase";
import { use } from "react";
import useSocket from "../../hooks/useSocket";
// import { itemPrice } from "../../Utils/viewHelper";
// import { priceAfterPermotion } from "../../Utils/viewHelper";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function Home() {
  const dispatch = useDispatch();
  const {
    table_detail,
    items_86_detail,
    clear_cache,
    show_table_already_inuse_modal,
    show_table_not_found_modal,
    filter_tag_detail,
    show_item_unavailable_modal,
    show_item_unavailable_text,
    filters,
    trending_popular,
    search_input,
    search_text,
    show_session_expired_page,
    selected_item_detail,
    server_detail,
    server_order_id,
    qr_order,
    combine_order_status,
    order_offline_id,
  } = useSelector((state) => state.tableDetailReducer);

  const {
    show_complete_page,
    recent_order_id,
    cart_detail,
    show_cart_popup,
    reorder_item_ids,
    recent_order_detail,
    total_tip,
    pos_cart_detail,
    applied_voucher,
    applied_promocode,
    showQuantityErr,
    showQuantityErrMsg,
    quantityChecked,
    addMultipeItem,
    activeItemData,
    combined_order_Data,
    localCartDetails,
    void_menu_ticket,
    Cancelmenu_item,
    printjobCount,
    open_order,
    KitchenOrders,
    course_List,
    callServerData,
    acknowledge,
  } = useSelector((state) => state.cartDetailReducer);
  const {
    user_detail,
    server_user_detail,
    card_detail,
    xgate_points_popup,
    xgate_details: xgateDetails,
    xgate_coupons: xgateCoupons,
    applePaymentSucced,
    redirect_to_table,
    como_details,
    eber_details,
    token_expiration_time,
  } = useSelector((state) => state.authDetailReducer);
  const history = useHistory();
  const { is_payment_started, is_payment_cleared } = useSelector(
    (state) => state.paymentDetailReducer
  );

  const { socket, connectSocket, disconnectSocket } = useSocket();
  const inputRef = useRef();

  const { loading } = useSelector((state) => state.loaderReducer);
  const merchantId = table_detail?.Merchant?.id;
  const tableNumber = table_detail?.PosTableMaster?.table_number ?? "";
  const userId = user_detail?.id;
  const deviceId = "not-valid";
  const loyaltyEnabled = table_detail?.Merchant?.loyalty_enabled;

  const [exitOption, setExitOption] = useState(false);

  const handleSetActive = (to, element) => {
    if (document.getElementById(`${to}`)) {
      document.getElementById(`${to}`).scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
  };
  const isTableDataLoaded = getSessionStorageOrDefault(
    "isTableDataLoaded",
    false
  );

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total ?? 0);
        }, 0)
      : 0;
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;
  const defaultDiscountTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  const getPromotionTotalDis = () => {
    let res = getBasketTotalAmount(true);
    let promotionDiscount = 0;
    if (applied_promocode && applied_promocode !== null) {
      if (applied_promocode.type == "Amount") {
        promotionDiscount = parseFloat(applied_promocode.amount);
        res = res - promotionDiscount >= 0 ? res - promotionDiscount : 0;
      } else if (applied_promocode.type == "Percentage") {
        promotionDiscount = (res * parseFloat(applied_promocode.amount)) / 100;
        res = res - promotionDiscount >= 0 ? res - promotionDiscount : 0;
      } else if (applied_promocode.type == "Re-Price") {
        promotionDiscount = parseFloat(applied_promocode.amount);
        res = promotionDiscount;
      }
    }
    return res;
  };
  const salesTaxPer = parseFloat(table_detail?.SalesTaxRate ?? 0);

  const subCategoryLength = table_detail?.SubCategory?.length;
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [lastScrollHeight, setLastScrollHeight] = useState([]);
  const [selectActiveDropDown, setSelectActiveDropDown] = useState(false);
  const [dpStates, setDpStates] = useState({
    categories: true,
    search: false,
    isMenuVisible: false,
  });

  // const [serverNotified, setServerNotified] = useState(false);

  const subCatLength =
    table_detail?.SubCategory && table_detail?.SubCategory.length > 0
      ? table_detail?.SubCategory.length
      : 0;
  const isFilterOrSearchApplied =
    (filters && filters !== "") || (search_input && search_input !== "")
      ? true
      : false;
  const [showCartPage, setShowCartPage] = useState(show_cart_popup ?? false);
  const [showAddItem, setShowAddItem] = useState(false);

  const [showBumdleItem, setShowBumdleItem] = useState(false);

  const [pageNumber, setPageNumber] = useState(
    subCatLength > 5 ? 5 : subCatLength
  );
  const [wrapperClass, setWrapperClass] = useState("");
  // const [hideHeader, setHideHeader] = useState(false);

  const [menuHeight, setMenuHeight] = useState(false);
  const [applyFilters, setApplyFilters] = useState(filters);
  // const [trendingPopular , setTrendingPopular] = useState(trending_popular);

  const [filtersNames, setFiltersNames] = useState("");

  const [applySearch, setApplySearch] = useState(search_input);
  const [selectSubCat, setSelectSubCat] = useState(0);

  const [showFilterCon, setShowFilterCon] = useState(false);
  const [promotionArr, setPromotionArr] = useState([]);
  const [promotionDisplayArr, setPromotionDisplayArr] = useState([]);
  const [reorderArr, setReorderArr] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const showPostTaxPrice =
    table_detail?.Merchant?.MerchantFinanceInfo?.menu_pricing_default == "2"
      ? true
      : false;

  const [showTipPopup, setShowTipPopup] = useState(0);
  const [showAccountPopUp, setShowAccountPopUp] = useState(0);
  const [showPromocodePopup, setShowPromocodePopup] = useState(0);
  const [totalCartAmount, setTotalCartAmount] = useState(
    defaultTotalCartAmount - 0
  );
  const [totalServiceCharge, setTotalServiceCharge] =
    useState(defaultServiceCharge);
  const [totalSaleTax, setTotalSaleTax] = useState(defaultTotalSaleTax);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalItemLevelDiscount, setTotalItemLevelDiscount] = useState(0);
  const [promoDiscount, setPromoDiscount] = useState(0);
  const [totalTip, setTotalTip] = useState(total_tip);
  const [isCartChanged, setIsCartChanged] = useState(false);

  // useEffect(()=>{
  //   if (showAccountPopUp === 1) {
  //     dispatch(getOrderHistoryDetail(merchantId));
  //   }
  // },[showAccountPopUp])

  const showPayPopUp =
    table_detail?.QrOrdering?.payment_accepted == "yes" &&
    table_detail?.QrOrdering?.payment_required_upfront != "yes" &&
    getCartFiredItemCount(cart_detail) > 0;

  const isUpFrontPayment =
    table_detail?.QrOrdering?.payment_required_upfront == "yes" ? true : false;

  const [slidePaymentPage, setSlidePaymentPage] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(false);

  const [slidePayConfirmPage, setSlidePayConfirmPage] = useState(false);
  const [showPayConfirmPage, setShowPayConfirmPage] = useState(false);
  const [cartBgWhite, setCartBgWhite] = useState(false);
  const [showMorePermotion, setShowMorePermotion] = useState(false);
  // const history = useHistory();

  const check_MenuId_Not_Null = useCallback((menu_item) => {
    for (let i = 0; i < menu_item.length; i++) {
      if (!menu_item[i].menu_item_id) {
        return false;
      }
    }
    return true;
  });

  const togglePaymentPopUp = useCallback(
    (event) => {
      // event.preventDefault();
      if (showPaymentPage) {
        toggleCartPopUp(false);
        setSlidePaymentPage(false);
        setCartBgWhite(false);
        setTimeout(() => {
          setShowPaymentPage(!showPaymentPage);
        }, 500);
      } else {
        setShowPaymentPage(!showPaymentPage);
        setTimeout(() => {
          toggleCartPopUp(true);
          setSlidePaymentPage(true);
        }, 100);
        setTimeout(() => {
          setCartBgWhite(true);
        }, 500);
      }
    },
    [showPaymentPage, slidePaymentPage]
  );

  const togglePayConfirmPopUp = useCallback(
    (event) => {
      // event.preventDefault();
      if (showPayConfirmPage) {
        toggleCartPopUp(false);
        setSlidePayConfirmPage(false);
        setCartBgWhite(false);
        setTimeout(() => {
          setShowPayConfirmPage(!showPayConfirmPage);
        }, 500);
      } else {
        setShowPayConfirmPage(!showPayConfirmPage);
        setTimeout(() => {
          toggleCartPopUp(true);
          setSlidePayConfirmPage(true);
        }, 100);
        setTimeout(() => {
          setCartBgWhite(true);
        }, 500);
      }
    },
    [showPayConfirmPage, slidePayConfirmPage]
  );

  const removeOtherCombinedOrders = (data, index, combined_menu) => {
    const merchantId = server_detail.CurrentRoom.merchant_id;
    const payload = removeTicketPayload(data, index, combined_menu, merchantId);
    dispatch(removeCombineOrder(payload));
  };

  console.log(useSelector((state) => state.cartDetailReducer))

  function orderPlaceHandler(
    onlyTip = false,
    newTip = totalTip,
    isPayment = false,
    is_server_fired = true, //  order will be fired if is_server_fired  is true
    exitsScreenCondition = "yes"
  ) {
    let body = getCartBody({
      recent_order_id,
      cart_detail,
      pos_cart_detail,
      user_detail,
      table_detail,
      totalTip: newTip,
      applied_voucher,
      totalDiscount: totalDiscount,
      promoDiscount: promoDiscount,
      finalTotalAmt: getCartBasketTotalAmount(true),
      applied_promocode,
      xgateDetails,
      onlyTip,
      server_detail,
      server_user_detail,
      Cancelmenu_item,
      void_menu_ticket,
      KitchenOrders,
      course_List,
      printjobCount,
      is_server_fired,
      exitsScreenCondition,
      localCartDetails,
      qr_order,
      open_order,
      loyaltyToken: eber_details?.id ?? undefined,
    });

    if (isPayment && !server_detail) {
      dispatch(changePaymentDetail({ paymentBody: body }));
      if (checkArray(pos_cart_detail?.payment_type)) {
        dispatch(
          changePaymentDetail({
            paymentType: "split",
            splitPaymentArr: pos_cart_detail?.payment_type,
            wayToSplit: pos_cart_detail?.payment_type.length,
            splitType:
              pos_cart_detail?.split_type_name == "Split Evenly"
                ? 1
                : pos_cart_detail?.split_type_name == "Split By Item"
                ? 2
                : 3,
          })
        );
        // history.push("/payment-confirm");
        togglePayConfirmPopUp();
      } else {
        // history.push("/payment");
        // if (!checkNull(recent_order_id,false)){
        //   dispatch(orderPlaceDetail(body, onlyTip, true));
        // }
        togglePaymentPopUp();
      }
    } else {
      if (
        (body.menu_item.length <= 0 && body.is_new_order == "yes") ||
        (exitsScreenCondition == "no" &&
          body?.menu_item.length <= 0 &&
          body?.is_new_order == "yes")
      )
        return; //  order : new && menu_item.lenght>1 && exit option : no

      // dispatch(checkOrderrStatus());
      // dispatch(checkOrderrStatus(payload));
      if (body.menu_item.length && check_MenuId_Not_Null(body?.menu_item)) {
        dispatch(orderPlaceDetail(body, onlyTip));
      }
      if (onlyTip == false) {
        // handleSendOrderData();
        history.push("/new-items");
      }
    }
  }

  // handle send order data in firbase

  const submitOrderHandler = () => {
    if (
      checkArray(pos_cart_detail?.payment_type) &&
      pos_cart_detail?.payment_type.findIndex((p) => p.isPaid == true) > -1
    ) {
      if (isUpFrontPayment) {
        orderPlaceHandler(false, totalTip, true, true);
      } else {
        dispatch(
          showNotificationWithTimeout(
            `Order has been split can not add items now`,
            "error"
          )
        );
      }
    } else if (
      cart_detail?.filter(
        (p) => p?.is_fired == false || p?.is_server_fired == "no"
      )?.length > 0
    ) {
      if (isUpFrontPayment) {
        orderPlaceHandler(false, totalTip, true);
      } else {
        orderPlaceHandler();
      }
    }
  };

  // useEffect(() => {
  //   if (!socket) {
  //     connectSocket({ connectionPayload });
  //   }
  //   return () => {
  //     disconnectSocket();
  //   };
  //   console.log(recent_order_id);
  // }, [recent_order_id]);

  const tipChangeHandler = (newTipVal) => {
    setTotalTip(newTipVal);
    if (recent_order_id !== "") {
      orderPlaceHandler(true, newTipVal);
    }
    setShowTipPopup(0);
  };

  const getPostSaleTaxPrice = (selectItem, val) => {
    let res = parseFloat(checkIsNum(val, 0));
    if (selectItem?.dinein_price?.sales_tax == true) {
      const resTax = (res * salesTaxPer) / 100;
      res = res + resTax;
    }
    return res;
  };

  const getPostSaleTaxCartPrice = (selectItem, val) => {
    let res = parseFloat(checkIsNum(val, 0));
    if (selectItem?.is_sales_tax == true) {
      const resTax = (res * salesTaxPer) / 100;
      res = res + resTax;
    }
    return res;
  };

  const checkLatestVersion = () => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  };

  const items86Arr = checkArray(items_86_detail)
    ? pluckArray(items_86_detail, "id")
    : [];

  function removeItemHandler(index) {
    const itemIndx = cart_detail.findIndex((p) => p.offline_id == index);
    if (itemIndx > -1) {
      dispatch(removeItemCartDetail(itemIndx));
    }
    setIsCartChanged(!isCartChanged);
  }

  const increaseItem = () => {
    const activeDartItemObj = activeItemData;
    const itemIndx = cart_detail.findIndex(
      (p) => p.offline_id == activeDartItemObj?.offline_id
    );
    if (itemIndx > -1) {
      const newItem = { ...cart_detail[itemIndx] };
      newItem.quantity = newItem.quantity + 1;
      newItem.total = newItem.amount * newItem.quantity;
      const serviceChargeAmount = 0;
      const salesTaxAmount =
        newItem?.is_sales_tax == "1"
          ? parseFloat(
              ((newItem.total + serviceChargeAmount) * salesTaxPer) / 100
            )
          : 0;
      newItem.sales_tax_amount = salesTaxAmount;
      dispatch(updateItemCartDetail(itemIndx, newItem));
    }
    setIsCartChanged(!isCartChanged);
  };
  const increaseItemCheck = (activeDartItemObj, item) => {
    increaseItem();
    dispatch(setActiveItemData(activeDartItemObj));
  };

  const decreaseItem = (index) => {
    const itemIndx = cart_detail.findIndex((p) => p.offline_id == index);
    if (itemIndx > -1) {
      const newItem = { ...cart_detail[itemIndx] };
      if (newItem.quantity - 1 > 0) {
        newItem.quantity = newItem.quantity - 1;
        newItem.total = newItem.amount * newItem.quantity;
        const serviceChargeAmount = 0;
        const salesTaxAmount = newItem?.is_sales_tax
          ? parseFloat(
              ((newItem.total + serviceChargeAmount) * salesTaxPer) / 100
            )
          : 0;
        newItem.sales_tax_amount = salesTaxAmount;
        dispatch(updateItemCartDetail(itemIndx, newItem));
        setIsCartChanged(!isCartChanged);
      } else {
        removeItemHandler(index);
      }
    }
  };

  // Promotions array

  const makePromotionItem = (promotion) => {
    let copyPromotionArr = [];
    const isValidVoucher = checkVoucherAvailable(
      promotion?.datetime,
      table_detail,
      table_detail?.Merchant?.timezone_offset ?? "00:00"
    );

    let isMatch = true;
    if (+promotion.loyalty_only_promotion) {
      isMatch = eber_details?.redeemable_list?.find(
        (r) => promotion.code === r.pos_redeem_sku
      )
        ? true
        : false;
    }

    if (promotion?.MenuItem?.length > 0 && isValidVoucher && isMatch) {
      promotion.MenuItem.map((itemKey, promoItemIndex) => {
        const promoSubKey = table_detail?.SubCategory?.findIndex((p) => {
          let promRes = false;
          const subIdCheck =
            itemKey?.categorisation == "SubCategory"
              ? itemKey?.sub_category_id
              : itemKey?.sub_class_id;
          if (p.id == subIdCheck) {
            promRes = true;
          }
          return promRes;
        });
        const promoItemKey = table_detail?.SubCategory?.[
          promoSubKey
        ]?.MenuItem.findIndex((p) => p.id == itemKey?.id);
        if (
          promoSubKey > -1 &&
          checkSubCatAvailable(
            table_detail?.SubCategory?.[promoSubKey],
            table_detail
          ) &&
          table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey]
        ) {
          const promoItem = deepCopy(
            table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey]
          );
          const accutalPrice = parseFloat(promoItem?.dinein_price?.DineIn ?? 0);
          let promoPrice = 0;
          let discountPrice = 0;
          if (promotion.type == "Amount") {
            discountPrice = parseFloat(promotion.amount);
            promoPrice =
              accutalPrice - discountPrice >= 0
                ? accutalPrice - discountPrice
                : 0;
          } else if (promotion.type == "Percentage") {
            discountPrice = (accutalPrice * parseFloat(promotion.amount)) / 100;
            promoPrice =
              accutalPrice - discountPrice >= 0
                ? accutalPrice - discountPrice
                : 0;
          } else if (promotion.type == "Re-Price") {
            discountPrice = parseFloat(promotion.amount);
            promoPrice = discountPrice;
          }
          promoItem.dinein_price.DineIn = promoPrice;
          const accutalPostTaxPrice = parseFloat(
            promoItem?.dinein_price?.DineInPostTax ?? 0
          );
          let promoPostTaxPrice = 0;
          let discountPostTaxPrice = 0;
          if (promotion.type == "Amount") {
            discountPostTaxPrice = parseFloat(promotion.amount);
            promoPostTaxPrice = getPostSaleTaxPrice(
              promoItem,
              accutalPrice - discountPostTaxPrice >= 0
                ? accutalPrice - discountPostTaxPrice
                : 0
            );
          } else if (promotion.type == "Percentage") {
            discountPostTaxPrice =
              (accutalPostTaxPrice * parseFloat(promotion.amount)) / 100;
            promoPostTaxPrice =
              accutalPostTaxPrice - discountPostTaxPrice >= 0
                ? accutalPostTaxPrice - discountPostTaxPrice
                : 0;
          } else if (promotion.type == "Re-Price") {
            discountPostTaxPrice = parseFloat(promotion.amount);
            promoPostTaxPrice = getPostSaleTaxPrice(
              promoItem,
              discountPostTaxPrice
            );
          }

          promoItem.dinein_price.DineInPostTax = promoPostTaxPrice;
          promoItem.promotions = {
            id: promotion.id,
            type: promotion.type,
            code: promotion.code,
            name: promotion.promotion_name,
            auto_apply: promotion.auto_apply,
            accutalPrice: accutalPrice,
            accutalPostTaxPrice: accutalPostTaxPrice,
            promotionTotaldiscount: discountPrice,
            amount: promotion.amount,
            discountPerItem: parseFloat(discountPrice),
            combo_allowed: promotion.combo_allowed,
            isLoyaltyVoucher:
              promotion?.loyalty_only_promotion == "1" ? "yes" : "no",
            voucherName: "",
          };
          copyPromotionArr.push(promoItem);
        }
      });
    }
    return copyPromotionArr;
  };

  const getPromotionItemDetails = () => {
    let copyPromotionArr = [];
    if (
      table_detail?.Promotions &&
      table_detail?.Promotions.filter(
        (p) => p?.auto_apply === "Yes" && p?.qualification_type == "item"
        // &&
        // (eber_details?.redeemable_list?.find(
        //   (r) => r?.pos_redeem_sku ===  p?.code
        // ))
        // p?.code===
      ).length > 0
    ) {
      const copyPromotions = deepCopy(
        table_detail?.Promotions.filter(
          (p) => p?.auto_apply === "Yes" && p?.qualification_type == "item"
          // &&
          // ( p?.loyalty_only_promotion &&eber_details?.redeemable_list?.find(
          //   (r) => r?.pos_redeem_sku ===  p?.code
          // ))
        )
      );
      copyPromotions.map((promotion, promoKey) => {
        const newProArr = makePromotionItem(promotion);
        if (newProArr.length > 0) {
          copyPromotionArr = [...copyPromotionArr, ...newProArr];
        }
      });
    }
    if (user_detail?.promotions && user_detail?.promotions.length > 0) {
      const copyCopounPromotions = deepCopy(user_detail?.promotions);
      copyCopounPromotions.map((promotion, promoKey) => {
        const newProArr = makePromotionItem(promotion);
        if (newProArr.length > 0) {
          copyPromotionArr = [...copyPromotionArr, ...newProArr];
        }
      });
    }
    setPromotionArr(copyPromotionArr);
  };

  // reorder array
  const makeReorderItems = (reorder) => {
    let copyReorderArr = [];
    if (reorder_item_ids?.length > 0) {
      reorder_item_ids.map((itemKey, promoItemIndex) => {
        const promoSubKey = table_detail?.SubCategory?.findIndex(
          (p) => p.MenuItem.findIndex((q) => q.id == itemKey?.menu_item_id) > -1
        );
        const promoItemKey = table_detail?.SubCategory?.[
          promoSubKey
        ]?.MenuItem.findIndex((p) => p.id == itemKey?.menu_item_id);
        if (
          promoSubKey > -1 &&
          table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey] &&
          checkItemAvailable(
            table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey],
            table_detail?.Merchant?.timezone_offset ?? "00:00",
            table_detail
          )
        ) {
          const promoItem = deepCopy(
            table_detail?.SubCategory?.[promoSubKey]?.MenuItem?.[promoItemKey]
          );
          promoItem.recomItem = itemKey;
          copyReorderArr.push(promoItem);
        }
      });
    }
    if (copyReorderArr.length > 0)
      setReorderArr([...reorderArr, ...copyReorderArr]);
  };

  // infinite scroller
  const [hasMore, setHasMore] = useState(true);
  const [sliceHasMore, setSliceHasMore] = useState(3);
  const [hasMoreChanged, setHasMoreChanged] = useState(false);
  const [itemDetails, setItemDetails] = useState("");

  const [scrollerItems, setScrollerItems] = useState(
    table_detail?.SubCategory?.slice(0, sliceHasMore)
  );
  const searchInputContainer = useRef(null);
  // const history = useHistory();
  // const navigate = useNavigation()
  let { id, type } = useParams();

  const prevHasMoreChanged = usePrevious(hasMoreChanged);
  const cartCount = getCartNewItemCount(cart_detail);

  useEffect(() => {
    dispatch(getTableDetailByFilter(id, applyFilters, applySearch));
  }, [applyFilters]);

  useEffect(() => {
    const servertempdata = JSON.parse(localStorage.getItem("serverData"));
    if (
      loading === false &&
      (show_table_not_found_modal === false || isTableDataLoaded === false) &&
      (table_detail === null || table_detail === undefined)
    ) {
      if (type == "server" && server_detail) {
        dispatch(getTableDetail(id, deviceId));
      } else if (type == "server") {
        const { merchantId, serverId } = JSON.parse(
          localStorage.getItem("serverData")
        );

        window.location.href = `${window.location.origin}/get_server_details/${merchantId}/${serverId}`;
      } else {
        clearSessionDetails();
        dispatch(getTableDetail(id, deviceId));
      }
    } else if (loading === false && isTableDataLoaded === false) {
      dispatch(emptyTableDetail());
    }

    // ***************************************
    // else if (
    //   loading === false &&
    //   !clear_cache &&
    //   table_detail &&
    //   table_detail !== null
    // ) {
    // dispatch(checkOpenOrder(merchantId, tableNumber));
    // }

    if (show_table_not_found_modal) {
      history.push("/login");
    }

    if (table_detail) {
      if (
        (table_detail?.Customer?.contact_email ||
          table_detail?.Customer?.eber_member_id ||
          table_detail?.Customer?.loyalty_member_id) &&
        !eber_details
      ) {
        history.push("/user-confirmation");
      } else if (server_user_detail) {
        if (show_table_not_found_modal === true) {
          history.push("/login");
        }
      } else if (
        user_detail === null ||
        user_detail === undefined ||
        show_table_not_found_modal === true
      ) {
        history.push("/login");
      }
    }
  }, [
    show_table_not_found_modal,
    user_detail,
    server_user_detail,
    clear_cache,
    table_detail,
  ]);

  

  useEffect(() => {
    /* check expired session */
    const checkExpired = checkExpiredSession(table_detail);
    const checkTokenExpired = token_expiration_time
      ? isTokenExpired(token_expiration_time)
      : "";
    const checkInactive =
      recent_order_detail === null && !server_detail
        ? checkInactiveSession()
        : false;
    if (checkExpired || checkInactive) {
      if (server_detail?.CurrentRoom) {
        history.push(
          `/get_server_details/${server_detail.CurrentRoom.merchant_id}/${server_detail.CurrentRoom.server_code}`
        );
      } else if (id && deviceId) {
        dispatch(getTableDetail(id, deviceId));
      } else {
        history.push("/session-expired");
      }
    }
    if (checkTokenExpired) {
      history.push("/session-expired");
    }
    /* check expired session END*/

    if (show_cart_popup) {
      dispatch({ type: TOGGLE_CART_PAGE, payload: false });
    }

    const newTotalCartAmount =
      cart_detail && cart_detail.length > 0
        ? cart_detail.reduce(function (sum, current) {
            return sum + parseFloat(current.total);
          }, 0)
        : 0;
    const newServiceCharge =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_service_charge == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.service_charge_amount);
              }, 0)
          )
        : 0;
    const newTotalSaleTax =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_sales_tax == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.sales_tax_amount);
              }, 0)
          )
        : 0;
    let defaultPromoDiscount = 0;
    // promotion discount
    if (
      cart_detail &&
      checkArray(
        cart_detail.filter(
          (p) =>
            checkNull(p?.promotions, false) &&
            p?.promotions?.type !== "Re-Price"
        )
      )
    ) {
      cart_detail
        .filter(
          (p) =>
            checkNull(p?.promotions, false) &&
            p?.promotions?.type !== "Re-Price"
        )
        .map((item) => {
          defaultPromoDiscount =
            defaultPromoDiscount +
            parseFloat(item?.promotions?.discountPerItem ?? 0) *
              parseInt(item?.quantity ?? 0);
        });
    }
    // promotion discount END
    let defaultItemLevelDiscount = 0;
    // promotion discount
    if (cart_detail && checkArray(cart_detail)) {
      cart_detail.map((item) => {
        let discountedPrc = item?.discount ?? 0;
        const itemTotalAmt =
          parseFloat(item?.amount ?? 0) * parseFloat(item?.quantity ?? 0);
        if (
          item?.discountType != "amt" &&
          discountedPrc > 0 &&
          itemTotalAmt > 0
        ) {
          discountedPrc = percentage(discountedPrc, itemTotalAmt, 4);
        }
        defaultItemLevelDiscount = defaultItemLevelDiscount + discountedPrc;
      });
    }
    // promotion discount END
    let defaultTotalDiscount = 0;
    //applied_voucher
    if (applied_voucher && applied_voucher.length > 0) {
      const newApplyVoucher = applied_voucher.map((applyVoucher) => {
        if (applyVoucher?.Type == "Cash") {
          const discountAmt = parseFloat(applyVoucher?.TypeValue ?? 0);
          applyVoucher.discountAmt = discountAmt;
          defaultTotalDiscount = defaultTotalDiscount + discountAmt;
        } else if (applyVoucher?.Type == "Discount") {
          const discountAmt =
            (parseFloat(applyVoucher?.TypeValue ?? 0) *
              defaultDiscountTotalCartAmount) /
            100;
          applyVoucher.discountAmt = discountAmt;
          defaultDiscountTotalCartAmount =
            defaultDiscountTotalCartAmount - discountAmt;
          defaultTotalDiscount = defaultTotalDiscount + discountAmt;
        }
        return applyVoucher;
      });
      dispatch({
        type: APPLY_VOUCHER_DETAIL_RECEIVED,
        payload: newApplyVoucher,
      });
    }
    if (applied_promocode && applied_promocode !== null) {
      defaultTotalDiscount = defaultTotalDiscount + getPromotionTotalDis();
    }
    // applied_voucher END

    setTotalDiscount(defaultTotalDiscount);
    setPromoDiscount(defaultPromoDiscount);
    setTotalItemLevelDiscount(defaultItemLevelDiscount);
    setTotalSaleTax(newTotalSaleTax);
    setTotalServiceCharge(newServiceCharge);
    setTotalCartAmount(newTotalCartAmount);
  }, [showAccountPopUp, showPromocodePopup, cart_detail, isCartChanged]);

  useEffect(() => {
    console.log(show_session_expired_page, is_payment_cleared,id, deviceId)
    if (show_session_expired_page && is_payment_cleared === false) {
      if (server_detail?.CurrentRoom) {
        history.push(
          `/get_server_details/${server_detail.CurrentRoom.merchant_id}/${server_detail.CurrentRoom.server_code}`
        );
      } else if (id && deviceId) {
        dispatch(getTableDetail(id, deviceId));
      } else {
        history.push("/session-expired");
      }
    }
  }, [show_session_expired_page]);

  useEffect(() => {
    if (show_complete_page && is_payment_cleared === false) {
      history.push("/completed");
    }
  }, [show_complete_page]);

  useEffect(() => {
    let newPromoDisplayArr = [];

    if (
      checkArray(promotionArr) &&
      table_detail?.PromotionalItems != null &&
      checkArray(Object.keys(table_detail?.PromotionalItems))
    ) {
      const copyPromotionArr = deepCopy(promotionArr);
      Object.keys(table_detail?.PromotionalItems).map((promoInx) => {
        const promoMenuIds = table_detail?.PromotionalItems[promoInx];
        if (checkArray(promoMenuIds)) {
          promoMenuIds.map((promoMenuId) => {
            const promomenuIndex = copyPromotionArr.findIndex(
              (p) => p.id == promoMenuId && p.promotions.id == promoInx
            );
            if (promomenuIndex > -1) {
              const promoItem = copyPromotionArr[promomenuIndex];
              if (
                filterPromoItemTableDetails(promoItem) &&
                checkItemAvailable(
                  promoItem,
                  table_detail?.Merchant?.timezone_offset ?? "00:00",
                  table_detail
                )
              ) {
                newPromoDisplayArr.push(promoItem);
              }
            }
          });
        }
      });
    } else {
      const copyPromotionArr = deepCopy(promotionArr);
      newPromoDisplayArr = copyPromotionArr.filter(
        (promoItem) =>
          filterPromoItemTableDetails(promoItem) &&
          checkItemAvailable(
            promoItem,
            table_detail?.Merchant?.timezone_offset ?? "00:00",
            table_detail
          )
      );
    }
    let isCodeMatch = true;

    const loyaltyPromotion = table_detail?.Promotions?.filter(
      (p) => +p.loyalty_only_promotion
    );
    const nonLoyaltyPromotion = table_detail?.Promotions?.filter(
      (p) => !+p.loyalty_only_promotion
    );

    let tempArr = [];
    table_detail?.Promotions?.map((promotion) => {
      const result = newPromoDisplayArr.filter((item2) =>
        promotion?.MenuItem?.some(
          (item1) => item1.id === item2.id && item1.name === item2.name
        )
      );
      if (+promotion.loyalty_only_promotion) {
        const isMatch = eber_details?.redeemable_list?.find(
          (r) => r?.pos_redeem_sku === promotion?.code
        );
        if (isMatch) tempArr = [...tempArr, ...result];
      } else {
        tempArr = [...tempArr, ...result];
      }
    });
    newPromoDisplayArr = [...tempArr];

    //

    const uniqueItems = [];
    const seenIds = new Set();

    for (const item of newPromoDisplayArr) {
      if (!seenIds.has(item.id)) {
        seenIds.add(item.id);
        uniqueItems.push(item);
      }
    }

    setPromotionDisplayArr(uniqueItems);
  }, [promotionArr]);

  const scrollToElementDiv = (additionHeight = 350) => {
    // const targetEl = document.getElementById(
    //   `element_item_id_${selected_item_detail?.id}`
    // );
    // const targetDiv = document.getElementById("menu-sections");
    // targetDiv.scrollTo({
    //   left: 0,
    //   top: targetEl.offsetTop - additionHeight,
    //   behavior: "smooth",
    // });
  };

  useEffect(() => {
    makeReorderItems();
  }, [reorder_item_ids]);

  useEffect(() => {
    dispatch(changeRedirectHome(false));
    getPromotionItemDetails();
    checkLatestVersion();
    if (
      selected_item_detail?.id !== undefined &&
      (!checkArray(reorderArr) || checkArray(promotionDisplayArr))
    ) {
      if (
        document.getElementById(`element_item_id_${selected_item_detail?.id}`)
      ) {
        scrollToElementDiv();
      }
    }

    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, [table_detail]);

  useEffect(() => {
    if (
      selected_item_detail?.id !== undefined &&
      (checkArray(reorderArr) || checkArray(promotionDisplayArr))
    ) {
      if (
        document.getElementById(`element_item_id_${selected_item_detail?.id}`)
      ) {
        scrollToElementDiv();
      }
    }
  }, [reorderArr, promotionDisplayArr]);

  // useEffect(() => {
  //   if (hasMoreChanged !== prevHasMoreChanged) {
  //     const newSubItem = table_detail?.SubCategory?.[selectSubCat];
  //     if (newSubItem) {
  //       const subItemEl = document.getElementById(`sub_id_${newSubItem.id}`);
  //       if (subItemEl) {
  //         subItemEl.click();
  //       }
  //     }
  //   }
  // }, [scrollerItems]);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        isHeaderAllUp === true
      ) {
        setWrapperClass("");
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setWrapperClass("scroll-active");
        setHideHeader(true);
      }
    },
    ...config,
  });

  const fetchMoreData = () => {
    if (scrollerItems.length === subCategoryLength) {
      setHasMore(false);
      return;
    }
    setSliceHasMore(sliceHasMore + 3);
    setScrollerItems(table_detail?.SubCategory?.slice(0, sliceHasMore + 3));
  };

  function checkItemHasTag(item) {
    let res = filters && filters !== "" ? false : true;
    const filterArr = filters && filters !== "" ? filters.split(",") : [];
    const applyFilterArr =
      filterArr.length > 0 && filter_tag_detail && filter_tag_detail !== ""
        ? filter_tag_detail.filter((item) =>
            filterArr.includes(item.id.toString())
          )
        : [];

    const filterSymbols =
      applyFilterArr.length > 0
        ? applyFilterArr
            .filter((p) => !p.name.includes("Contains"))
            .map((filter) => {
              return `${filter.id}`;
            })
        : [];
    const containfilterSymbols =
      applyFilterArr.length > 0
        ? applyFilterArr
            .filter((p) => p.name.includes("Contains"))
            .map((filter) => {
              return `${filter.id}`;
            })
        : [];

    if (
      containfilterSymbols.length > 0 &&
      filterSymbols.length == 0 &&
      item?.allergy_tags?.length == 0
    ) {
      res = true;
    } else if (res == false && item?.allergy_tags?.length > 0) {
      const allergies = item.allergy_tags;
      for (let i = 0; i < allergies.length; i++) {
        const element = allergies[i];
        if (
          containfilterSymbols.length > 0 &&
          containfilterSymbols.includes(`${element}`)
        ) {
          res = false;
          break;
        } else if (
          filterSymbols.includes(`${element}`) ||
          (filterSymbols.length == 0 &&
            containfilterSymbols.length > 0 &&
            !containfilterSymbols.includes(`${element}`))
        ) {
          res = true;
          if (containfilterSymbols.length == 0) break;
        }
      }
    }
    return res;
  }

  function checkItemHasMatchingModifiers(item) {
    let res = true;
    if (item && search_input && search_input !== "") {
      res = false;
      const searchInputText = search_input.toLowerCase();
      if (item?.menu_item_name.toLowerCase().indexOf(searchInputText) > -1) {
        res = true;
      } else if (
        item?.PosMenuitemsModifier &&
        item?.PosMenuitemsModifier.length > 0 &&
        item?.PosMenuitemsModifier.findIndex(
          (p) =>
            p?.Recipe?.recipe_name?.toLowerCase().indexOf(searchInputText) >
              -1 ||
            p?.Ingredient?.ingredient_name
              ?.toLowerCase()
              .indexOf(searchInputText) > -1
        ) > -1
      ) {
        res = true;
      } else if (
        // description
        item?.description &&
        item?.description.toLowerCase().indexOf(searchInputText) > -1
      ) {
        res = true;
      }
    }
    return res;
  }

  function checkItemHasMatch(subCat, item) {
    let res = true;
    if (filters && filters !== "" && search_input && search_input !== "") {
      res = checkItemHasMatchingModifiers(item) && checkItemHasTag(item);
    } else if (filters && filters !== "") {
      res = checkItemHasTag(item);
    } else if (search_input && search_input !== "") {
      res =
        checkItemHasMatchingModifiers(item) ||
        subCat?.name.toLowerCase().indexOf(search_input.toLowerCase()) > -1;
    }
    return res;
  }

  function checkPromoItemHasMatch(item) {
    let res = true;
    if (filters && filters !== "" && search_input && search_input !== "") {
      res = checkItemHasMatchingModifiers(item) && checkItemHasTag(item);
    } else if (filters && filters !== "") {
      res = checkItemHasTag(item);
    } else if (search_input && search_input !== "") {
      res =
        checkItemHasMatchingModifiers(item) ||
        "promotions".indexOf(search_input.toLowerCase()) > -1;
    }
    return res;
  }

  function filterSubCatTableDetails(subCat) {
    if (
      ((filters && filters !== "") ||
        (search_input && search_input !== "") ||
        (trending_popular && trending_popular != "")) &&
      table_detail.Merchant &&
      table_detail.SubCategory &&
      table_detail.SubCategory.length > 0
    ) {
      return (
        subCat?.MenuItem.findIndex(
          (p) =>
            checkItemHasMatch(subCat, p) &&
            checkItemAvailable(
              p,
              table_detail?.Merchant?.timezone_offset ?? "00:00",
              table_detail
            ) &&
            checkTrendingAndPopularApplied(p, true)
        ) > -1 ||
        (search_input &&
          search_input !== "" &&
          !(filters && filters !== "") &&
          subCat?.name.toLowerCase().indexOf(search_input.toLowerCase()) > -1)
      );
    }
    return true;
  }
  function filterPromoCatTableDetails() {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      table_detail.Merchant
    ) {
      // hide when search and filter use
      return false;
      // hide when search and filter use END

      return (
        promotionArr.findIndex((p) => checkPromoItemHasMatch(p)) > -1 ||
        (search_input &&
          search_input !== "" &&
          !(filters && filters !== "") &&
          "promotion".toLowerCase().indexOf(search_input.toLowerCase()) > -1)
      );
    }
    return true;
  }

  const checkTrendingAndPopularApplied = (item, test) => {
    let res = true;
    const filterArr =
      trending_popular && trending_popular !== ""
        ? trending_popular.split(",")
        : [];
    if (
      filterArr.includes("-1") &&
      (item.is_trending != 1 || item.is_popular == 1)
    ) {
      res = false;
    }
    if (filterArr.includes("-2") && item.is_popular != 1) {
      res = false;
    }

    return res;
  };

  function filterItemTableDetails(subCat, item) {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      table_detail.Merchant &&
      table_detail.SubCategory &&
      table_detail.SubCategory.length > 0
    ) {
      return checkItemHasMatch(subCat, item);
    }
    return true;
  }

  function filterPromoItemTableDetails(item) {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      table_detail?.Merchant
    ) {
      // hide when search and filter use
      return false;
      // hide when search and filter use END
      return checkPromoItemHasMatch(item);
    }
    return true;
  }

  const toggleCartPopUp = useCallback(
    (event) => {
      // event.preventDefault();
      setShowCartPage(!showCartPage);
      // if (!showCartPage || is_payment_started) {
      //   server_order_id &&
      // dispatch(checkOpenOrder(merchantId, tableNumber, userId));

      // }
    },
    [showCartPage, table_detail, user_detail]
  );

  const addItemPopUp = useCallback((event) => {
    setShowAddItem(true);
    // document.html.style.overflow = "hidden";
    document.getElementsByTagName("html")[0].style.overflow = "hidden";

    // document.body.style.overflow = "unset";
    // document.body.style.touchAction = 'none'
    // const element = document.getElementById("root");
    // if (element) {
    //   element.style.overflow = "overlay"; // Change to the desired style here
    //   // menuSections.classList.add('overflow-h');

    // }

    const menuSections = document.querySelector(".menu-sections");
    if (menuSections) {
      // menuSections.style.overflow = "hidden"; // Change to the desired style here
      // menuSections.classList.add('overflow-h');
    }
    // setTimeout(() => {
    //   document.body.style.overflow = 'hidden'
    // }, 500);
  }, []);

  // useEffect(() => {
  //   const element = document.getElementById("root");
  //   if (element) {
  //     element.style.overflow = "initial"; // Change to the desired style here
  //     // document.body.style.overflow = 'hidden'
  //     // document.body.style.overflow = 'unset'
  //   }
  // }, []);

  const addBundlePopUp = useCallback((event) => {
    setShowBumdleItem(true);
    // document.html.style.overflow = "hidden";
    document.getElementsByTagName("html")[0].style.overflow = "hidden";

    const element = document.getElementById("root");
    if (element) {
      // element.style.overflow = "initial"; // Change to the desired style here
      // document.body.style.overflow = 'hidden'
      // document.body.style.overflow = "unset";
      // element.classList.add('overflow-h');
    }
    // document.body.style.touchAction = "none";
    const menuSections = document.querySelector(".menu-sections");
    if (menuSections) {
      // menuSections.style.overflowY = "hidden"; // Change to the desired style here
      // menuSections.classList.add('overflow-h');
    }
    // setTimeout(() => {
    //   document.body.style.overflow = 'hidden'
    // }, 500);
  }, []);

  // useEffect(() => {

  //   dispatch(getOpenOrderData(merchantId, tableNumber, userId));
  // }, []);

  useEffect(async () => {
    if (quantityChecked) {
      if (showCartPage) {
        submitOrderHandler();
      } else {
        orderPlaceHandler(false, totalTip, true, true);
      }
      dispatch(quantityCheckedClose());
      // dispatch(emptyCartDetail());

      if (merchantId && !socket) {
        const deviceID = await getDeviceId();
        const connectionPayload = {
          device_id: deviceID,
          merchant_id: merchantId,
          type: "qr",
        };

        connectSocket({ connectionPayload });
      }
      const payload = {
        merchantId: merchantId,
        tableNumber: tableNumber,
        userId: userId,
      };
    }

    return () => {
      disconnectSocket();
    };
  }, [quantityChecked, recent_order_id,merchantId]);

  useEffect(async () => {
      if (merchantId && !socket && recent_order_id ) {
        const deviceID = await getDeviceId();
        const connectionPayload = {
          device_id: deviceID,
          merchant_id: merchantId,
          type: "qr",
        };

        connectSocket({ connectionPayload });
      }
      const payload = {
        merchantId: merchantId,
        tableNumber: tableNumber,
        userId: userId,
      };

    return () => {
      disconnectSocket();
    };
  }, [recent_order_id,merchantId]);

  // useEffect(async () => {
  //   const deviceID = await getDeviceId();
  //   const connectionPayload = {
  //     device_id: deviceID,
  //     merchant_id: merchantId,
  //     type: "qr",
  //   };

  //   console.log(order_offline_id, socket, merchantId);

  //   if (merchantId && !socket && order_offline_id) {
  //     console.log("Merchant ID received, connecting to socket...");

  //     connectSocket({ connectionPayload });
  //   }
  //   return () => {
  //     disconnectSocket();
  //   };
  // }, [merchantId, order_offline_id]);

  // useEffect(()=>{
  //   -.log("inside the apple applePaymentSucced useffect ")
  //   if(applePaymentSucced)
  //   {

  //     // submitOrderHandler();
  //     // orderPlaceHandler(false, totalTip, true, true);
  //   }

  // },[applePaymentSucced])

  const ratingChangeHandler = useCallback(
    (value) => {
      const merchantId = user_detail?.merchant_id;
      const customerId = user_detail?.id;
      updateRatingDetail({
        rating: value ?? "",
        merchant_id: merchantId ?? "",
        customer_id: customerId ?? "",
        order_id: recent_order_detail?.order_list?.[0]?.order_offline_id ?? "",
        review: "",
      });
    },
    [user_detail]
  );

  useEffect(() => {
    if (combine_order_status && combined_order_Data.length > 1) {
      const combined_menu = filter_CombineOrder_Data(combined_order_Data);

      combined_order_Data.map((data, index) => {
        return removeOtherCombinedOrders(data, index, combined_menu);
      });
      dispatch(combineOrderStatus(false));
    }
  }, []);

  // const handleScroll = () => {
  //   // const elements = document.querySelectorAll('[id^="sub_id_"]');

  //   // elements.forEach((element) => {
  //   //   const rect = element.getBoundingClientRect();

  //   //   if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
  //   //     // Do something with the element ID on top
  //   //   }
  //   // });
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;

    // if (isFilterOrSearchApplied) {
    //   setLastScrollHeight([]);
    //   setPageNumber(subCatLength > 5 ? 5 : subCatLength);
    // } else {
    //   if (lastScrollHeight.indexOf(headerPageScrollHeight) === -1) {
    //     setLastScrollHeight([...lastScrollHeight, headerPageScrollHeight]);
    //   }
    // }

    // if (headerPageOffset > lastScrollTop) {
    //   // down scroll
    //   const showNextPage =
    //     headerPageOffset + headerPageClientHeight >=
    //     headerPageScrollHeight - 20;
    //   if (table_detail?.SubCategory && showNextPage) {
    //     if (pageNumber + 5 < table_detail?.SubCategory.length) {
    //       setPageNumber(pageNumber + 5);
    //     } else {
    //   setPageNumber(table_detail?.SubCategory.length);
    //     }
    //   }
    // } else {
    //   // up scroll
    //   if (
    //     pageNumber > 5 &&
    //     lastScrollHeight.length > 1 &&
    //     headerPageOffset + headerPageClientHeight <
    //       lastScrollHeight[lastScrollHeight.length - 2]
    //   ) {
    //     if (table_detail?.SubCategory.length < 5) {
    //       setPageNumber(table_detail?.SubCategory.length);
    //     } else {
    //       setPageNumber(pageNumber - 5);
    //     }
    //  setLastScrollHeight(
    //       lastScrollHeight.slice(0, lastScrollHeight.length - 2)
    //     );
    //   }
    // }

    if (
      headerPageOffset &&
      headerPageOffset > 50 &&
      headerPageScrollHeight > headerPageClientHeight - 168 &&
      hideHeader === false
    ) {
      setHideHeader(true);
      // setWrapperClass("scroll_active");
    } else if (headerPageOffset === 0 && hideHeader === true) {
      setHideHeader(false);
      // setWrapperClass("");
    }
    // if (isFilterOrSearchApplied) {
    //   setLastScrollTop(0);
    // } else {
    //   setLastScrollTop(headerPageOffset);
    // }
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  const clearSessionDetails = () => {
    // sessionStorage.clear();
    dispatch(startNewSession());
    // dispatch(tableInitialState())

    // localStorage.clear('applicationState');
    // window.location.reload();
  };

  function getBasketTotalAmount() {
    let res = 0;
    if (
      cart_detail &&
      cart_detail.filter((p) => p.is_added == false).length > 0
    ) {
      cart_detail
        .filter((p) => p.is_added == false)
        .forEach((item) => {
          const amount =
            showPostTaxPrice && item?.is_sales_tax
              ? getPostSaleTaxCartPrice(item, item.total)
              : parseFloat(item.total ?? 0);
          res += amount;
        });
    }
    return (
      <>
        <small className="fw-bolder">{`${table_detail?.currency ?? ""}`}</small>
        {res.toFixed(2)}
      </>
    );
  }
  function getBasketTotalAmount() {
    let res = 0;
    if (
      cart_detail &&
      cart_detail.filter((p) => p.is_added == false).length > 0
    ) {
      cart_detail
        .filter((p) => p.is_added == false)
        .forEach((item) => {
          const amount =
            showPostTaxPrice && item?.is_sales_tax
              ? getPostSaleTaxCartPrice(item, item.total)
              : parseFloat(item.total ?? 0);
          res += amount;
        });
    }
    return res.toFixed(2);
  }
  function getBasketTotalQuantity() {
    let res = getCartNewItemCount(cart_detail);
    return (
      <div
        className="items-count"
        style={{ backgroundColor: table_detail?.QrOrdering?.brand_main_color }}
      >
        {" "}
        {res}{" "}
      </div>
    );
  }

  function getCartBasketTotalAmount(onlyTotal = false) {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      const totalTipCh = parseFloat(totalTip.toString().replace("$", ""));
      const totalDisCh = parseFloat(
        onlyTotal ? 0 : totalDiscount + totalItemLevelDiscount
      );
      res =
        totalCartAmount +
        totalServiceCharge +
        totalTipCh +
        totalSaleTax -
        totalDisCh;
    }

    if (onlyTotal) {
      return res;
    }
    return (
      <>
        <small className="fw-bolder">{`${table_detail?.currency ?? ""}`}</small>
        {parseRound(res)}
      </>
    );
  }

  function getItemTotalQuantity(
    item,
    type,
    onlyNum = false,
    submittedCartItemKey,
    activeCartItemKey
  ) {
    let res = 0;
    let cartItemKey;
    if (type === "promotion") {
      cartItemKey =
        cart_detail && cart_detail.length > 0 && item?.id
          ? cart_detail.findIndex(
              (p) => p.id == item.id && p.promotions?.id == item.promotions?.id
            )
          : -1;
    } else {
      cartItemKey =
        cart_detail && cart_detail.length > 0 && item?.id
          ? cart_detail.findIndex((p) =>
              p?.promotions?.id
                ? p.id == item.id && p.promotions?.id == item.promotions?.id
                : p.id == item.id
            )
          : -1;
    }
    if (cartItemKey !== -1) {
      res = cart_detail
        .filter((cartItem) => cartItem.id == item.id)
        .reduce(function (sum, current) {
          return sum + parseInt(current.quantity);
        }, 0);
    }
    if (onlyNum) {
      return parseInt(res);
    }

    return res > 0 ? (
      <span
        style={{ color: table_detail?.QrOrdering?.brand_highlight_color }}
        className={
          submittedCartItemKey !== -1 && activeCartItemKey === -1
            ? `txt-green-count grey-color`
            : `txt-green-count`
        }
      >
        {res}
      </span>
    ) : null;
  }

  function getItemTags(item) {
    let res = null;
    // if (item?.is_popular && item?.is_popular === 1) {
    //   res = <span className="tag">Popular</span>;
    // } else if (item?.is_trending && item?.is_trending === 1) {
    //   res = <span className="tag blue">Trending</span>;
    // }
    // if(item?.is_popular && item?.is_popular === 1 &&item?.is_trending && item?.is_trending === 1)
    // {
    //   res = (
    //    <span className="d-flex align-items-center">
    //      <span className="d-flex align-items-center ml-3">
    //       <StyledSVG src={fireFlame} />
    //       <span className="item-tags ml-1">Popular</span>
    //     </span>
    //     <span className="d-flex align-items-center ml-3">
    //     <StyledSVG src={trending} color={"#9A9A9A"} />
    //     <span className="item-tags ml-1">Trending</span>
    //   </span>
    //    </span>
    //   );
    // }
    // else
    if (item?.is_popular && item?.is_popular === 1) {
      res = (
        <span className="d-flex align-items-center ml-3">
          <StyledSVG src={fireFlame} />
          <span className="item-tags ml-1">Popular</span>
        </span>
      );
    } else if (item?.is_trending && item?.is_trending === 1) {
      res = (
        <span className="d-flex align-items-center ml-3">
          <StyledSVG src={trending} color={"#9A9A9A"} />
          <span className="item-tags ml-1">Trending</span>
        </span>
      );
    }

    return res;
  }

  function addFilter(id, name) {
    if (id !== undefined && id > 0) {
      id = id.toString();
      let applyFiltersArr = applyFilters === "" ? [] : applyFilters.split(",");
      let filtername = filtersNames == "" ? [] : filtersNames.split(",");
      const checkIndex = applyFiltersArr.findIndex((p) => p == id);
      if (checkIndex === -1) {
        applyFiltersArr.push(id);
      } else {
        applyFiltersArr.splice(checkIndex, 1);
      }
      const checkIndexName = filtername.findIndex((p) => p == name);
      if (checkIndexName === -1) {
        filtername.push(name);
      } else {
        filtername.splice(checkIndexName, 1);
      }
      setApplyFilters(applyFiltersArr.join());
      setFiltersNames(filtername.join());
    }
    if (id !== undefined && id <= 0) {
      id = id.toString();
      let applyFiltersArr =
        trending_popular === "" ? [] : trending_popular.split(",");
      let filtername = filtersNames == "" ? [] : filtersNames.split(",");
      const checkIndex = applyFiltersArr.findIndex((p) => p == id);
      if (checkIndex === -1) {
        applyFiltersArr.push(id);
      } else {
        applyFiltersArr.splice(checkIndex, 1);
      }
      const checkIndexName = filtername.findIndex((p) => p == name);
      if (checkIndexName === -1) {
        filtername.push(name);
      } else {
        filtername.splice(checkIndexName, 1);
      }
      dispatch(updateTrendingPopularTags(applyFiltersArr.join()));
      setFiltersNames(filtername.join());
    }
  }

  function countActiveFilter() {
    let res = null;
    let applyFiltersArr = filters === "" ? [] : filters.split(",");
    const checkIndex = applyFiltersArr.findIndex((p) => p == id);
    if (applyFiltersArr.length > 0) {
      res = <span className="count">{applyFiltersArr.length}</span>;
    }
    return res;
  }

  function checkActiveFilter(id) {
    let res = false;
    if (id !== undefined) {
      id = id.toString();
      let applyFiltersArr = applyFilters === "" ? [] : applyFilters.split(",");
      let trending_popularData =
        trending_popular === "" ? [] : trending_popular.split(",");

      const checkIndex = applyFiltersArr.findIndex((p) => p == id);
      const checkIndexTrending = trending_popularData.findIndex((p) => p == id);

      if (checkIndex != -1) {
        res = true;
      }
      if (checkIndexTrending != -1) {
        res = true;
      }
    }
    return res;
  }

  const filterTest = () => {
    const res = [];
    filter_tag_detail?.map((subCat) => {
      res.push({ id: subCat.id, value: subCat.name, label: subCat.name });
    });
    return res;
  };

  const closeDropdown = (subIndex) => {
    setSelectSubCat(subIndex);
    var myDropdown = document.getElementById("myDropdownButton");
    myDropdown.classList.remove("show");
  };

  const getCategoriesName = useCallback(() => {
    const data =
      table_detail?.SubCategory &&
      table_detail?.SubCategory.filter(
        (SubCat) =>
          filterSubCatTableDetails(SubCat) &&
          checkSubCatAvailable(SubCat, table_detail)
      );
    return data[selectSubCat]?.name;
  }, [table_detail, selectSubCat]);

  const getCategoriesView = () => {
    return (
      <div className="w-100 ">
        <div
          onClick={() => {
            setDpStates({
              categories: true,
              search: false,
              isMenuVisible: !dpStates?.isMenuVisible,
            });
          }}
          style={{ height: "50px", width: "100%" }}
        >
          {dpStates.categories ? (
            <div
              className={`d-flex justify-content-between px-3 align-items-center h-100 `}
            >
              {/* <span>{getCategoriesName()}</span> */}
              <span className="text-nowrap text-truncate d-block ">
                {activeMenu ? activeMenu : getCategoriesName()}
              </span>

              <StyledSVG
                src={downDown}
                className={`${
                  dpStates.categories && dpStates.isMenuVisible ? "jjj" : ""
                } collapse-content`}
              />
            </div>
          ) : (
            <div
              className="d-flex justify-content-center px-3 align-items-center h-100"
              onClick={() => {
                setDpStates({
                  categories: true,
                  search: false,
                  isMenuVisible: !dpStates?.isMenuVisible,
                });
              }}
            >
              <StyledSVG src={downDown} />
            </div>
          )}
        </div>
        {dpStates.categories && dpStates.isMenuVisible && (
          <div style={{ overflow: "auto", height: "430px" }}>
            <ul>{getCategoriesTags()}</ul>
          </div>
        )}
      </div>
    );
  };

  const onkeySelect = (event) => {
    setDpStates({
      categories: false,
      search: true,
      isMenuVisible: false,
    });
    // alert(event.key)
    if (
      event.key == "Enter" ||
      event.key == "Return" ||
      event.key == "Done" ||
      event.keyCode == 13
    ) {
      // inputRef.current.blur();
      setDpStates({
        categories: false,
        search: true,
        isMenuVisible: false,
      });
    }
  };

  const getSearchView = () => {
    return (
      <div className="w-100 ">
        <div
          // onClick={() => {
          //   setDpStates({
          //     categories: false,
          //     search: true,
          //     isMenuVisible: !dpStates?.isMenuVisible,
          //   });
          // }}
          style={{ height: "50px", width: "100%" }}
        >
          {dpStates.search ? (
            <div className="d-flex justify-content-between px-3 align-items-center h-100 ">
              <div className="d-flex align-items-center w-100">
                <input
                  type="text"
                  // className={`dropdown-search  my-2 px-2 ${
                  //   selectActiveDropDown ? "w-100" : "w-50"
                  // }`}
                  className="w-100  dropdown-search"
                  value={search_text}
                  ref={inputRef}
                  onFocus={() => {
                    setDpStates({
                      categories: false,
                      search: true,
                      isMenuVisible: false,
                    });
                  }}
                  placeholder={filtersNames}
                  onKeyDown={onkeySelect}
                  onChange={(e) => {
                    if (e?.target?.value === "") {
                      // inputRef?.current.blur();
                      setApplySearch("");
                      dispatch(getTableDetailByFilter(id, applyFilters, ""));
                    } else {
                      setApplySearch(e?.target?.value);
                      dispatch(updateSearchText(e?.target?.value));
                      updateSearchText(e?.target?.value);
                      dispatch(
                        getTableDetailByFilter(
                          id,
                          applyFilters,
                          e?.target?.value
                        )
                      );
                    }
                  }}
                />
                {search_text && (
                  <StyledSVG
                    className="mr-2"
                    src={close}
                    width={"28px"}
                    height={"28px"}
                    onClick={() => {
                      // dispatch(updateSearchText(""));
                      setApplySearch("");
                      dispatch(updateSearchText(""));
                      updateSearchText("");
                      dispatch(getTableDetailByFilter(id, applyFilters, ""));
                      setDpStates({
                        categories: false,
                        search: true,
                        isMenuVisible: false,
                      });
                    }}
                  />
                )}
              </div>

              <StyledSVG
                src={search}
                onClick={() =>
                  setDpStates({
                    categories: false,
                    search: true,
                    isMenuVisible: !dpStates?.isMenuVisible,
                  })
                }
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center px-3 align-items-center  h-100">
              <StyledSVG
                src={search}
                onClick={() =>
                  setDpStates({
                    categories: false,
                    search: true,
                    isMenuVisible: true,
                  })
                }
              />
            </div>
          )}
        </div>
        {dpStates.search && dpStates.isMenuVisible && (
          <div style={{ overflow: "auto", height: "430px" }}>
            <ul>{getFilterModalTags()}</ul>
            {/* <ul>{"hrllo"}</ul> */}
          </div>
        )}
      </div>
    );
  };

  const getCategoriesTags = () => {
    const length =
      table_detail?.SubCategory &&
      table_detail?.SubCategory.filter(
        (SubCat) =>
          filterSubCatTableDetails(SubCat) &&
          checkSubCatAvailable(SubCat, table_detail)
      ).length;

    return (
      table_detail?.SubCategory &&
      table_detail?.SubCategory.filter(
        (SubCat) =>
          filterSubCatTableDetails(SubCat) &&
          checkSubCatAvailable(SubCat, table_detail)
      ).map(function (subCat, subIndex) {
        // res = (
        //   <ul>
        const backStyle = subCat?.images
          ? `url(${subCat?.images})  no-repeat center center / cover`
          : subCat?.pos_color ?? "#2793c2";
        const backClass = subCat?.images ? "overlay-show" : "";
        return (
          <li
            class={`${
              subIndex == length - 1
                ? "dropdown-item border-0"
                : "dropdown-item "
            } dropdown-item d-flex justify-content-between mx-3 p-3`}
          >
            <span
              onClick={() => {
                scrollView(subCat?.name ? "#sub_id_" + subCat?.id : "null");
                setSelectSubCat(subIndex);
                seIsOpen(!isOpen);
                setDpStates({
                  categories: true,
                  search: false,
                  isMenuVisible: !dpStates?.isMenuVisible,
                });
              }}
              id={subCat?.name ? "sub_id_" + subCat?.id : "null"}
            >
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#3E3E3E",
                  maxWidth: "200px",
                }}
                className="text-nowrap text-truncate d-block "
              >
                {subCat?.name}
              </span>
            </span>
          </li>
        );
      })
    );
  };
  function getFilterModalTags() {
    let res = [];
    if (filter_tag_detail && filter_tag_detail.length > 0) {
      res = (
        <ul>
          {table_detail?.QrOrdering?.hide_popular_trending_lable === "yes"
            ? null
            : customFilterArray.map((data, index) => {
                const checkActive_trending = checkActiveFilter(data.id);
                return (
                  <li
                    class={`${
                      index == filter_tag_detail.length - 1
                        ? "dropdown-item border-0"
                        : "dropdown-item "
                    } dropdown-item d-flex justify-content-between mx-3 p-3 ${
                      data?.name == "Popular" ? "dropDown-search-svg" : ""
                    }`}
                    key={index}
                    onClick={() => {
                      addFilter(
                        data?.name == "Trending" ? -1 : -2,
                        data?.name == "Trending" ? "Trending" : "Popular"
                      );
                      setDpStates({
                        categories: false,
                        search: true,
                        isMenuVisible: !dpStates?.isMenuVisible,
                      });
                    }}
                  >
                    <div>
                      <span className="filter-dropdown-symbol mr-2 ">
                        <StyledSVG
                          color={"#3E3E3E"}
                          width={"20px"}
                          height={"20px"}
                          src={data?.name == "Trending" ? trending : fireFlame}
                        />
                      </span>
                      <span className="filter-dropdown-name">{data?.name}</span>
                    </div>
                    {checkActive_trending && (
                      <StyledSVG
                        src={removeIcon}
                        width={"20px"}
                        height={"20px"}
                      />
                    )}
                  </li>
                );
              })}

          <p className="dropdown-text m-3">Preference Filters</p>
          {filter_tag_detail.map(function (tag, index) {
            const checkActive = checkActiveFilter(tag.id);

            return (
              <li
                class={`${
                  index == filter_tag_detail.length - 1
                    ? "dropdown-item border-0"
                    : "dropdown-item "
                } dropdown-item d-flex justify-content-between mx-3 p-3`}
                key={index}
                onClick={() => {
                  addFilter(tag.id, tag?.name.replace("Contains", "No"));
                  setDpStates({
                    // categories: false,
                    // search: !dpStates?.search,
                    categories: false,
                    search: true,
                    isMenuVisible: !dpStates?.isMenuVisible,
                  });
                }}
              >
                <div>
                  <span className="filter-dropdown-symbol mr-2">
                    {tag?.symbol ? tag?.symbol.replace("Contains", "No") : ""}
                  </span>
                  <span className="filter-dropdown-name">
                    {tag?.name ? tag?.name.replace("Contains", "No") : ""}
                  </span>
                </div>
                {checkActive && (
                  <StyledSVG src={removeIcon} width={"20px"} height={"20px"} />
                )}
              </li>
            );
          })}
        </ul>
      );
    }

    return res;
  }
  const exit = () => {
    dispatch(redirectTable(false));
    dispatch(emptyTableDetail());
    dispatch(emptyComoDetails());
    dispatch(emptyUserDetail());
    dispatch(resetOrderData());
    const orderObject = {
      order_id: "",
      order_offline_id: "",
    };
    dispatch(setOrderData(orderObject));
    if (server_detail.CurrentRoom) {
      // history.push(
      //   `/get_server_details/${server_detail.CurrentRoom.merchant_id}/${server_detail.CurrentRoom.server_code}`
      // );
      history.push(`/table-Viewer`);
    } else {
      history.push(
        `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
      );
    }
  };

  useEffect(() => {
    if (redirect_to_table) {
      dispatch(redirectTable(false));
      exit();
      dispatch(redirectTable(false));
    }
  }, [redirect_to_table]);

  function getItemFilterTags(item) {
    let res = null;
    if (item?.allergy_tags && item?.allergy_tags.length > 0) {
      res = (
        <ul class="list-inline  d-inline-flex">
          {item?.allergy_tags.map(function (tag, index) {
            const filterTagKey =
              filter_tag_detail && filter_tag_detail.length > 0
                ? filter_tag_detail.findIndex((p) => p.id == tag)
                : null;
            const filterTag =
              filterTagKey !== null && filterTagKey !== undefined
                ? filter_tag_detail[filterTagKey]
                : null;
            const style =
              filterTag?.color && filterTag?.color !== ""
                ? { color: "#A1A1A1", fontSize: "13px", fontWeight: "600" }
                : {};
            const className =
              filterTag?.color && filterTag?.color !== "" ? "" : "gray";
            return (
              <li
                style={style}
                key={index}
                // className={"list-inline-item"}
                className="ml-1"
              >
                {filterTag?.symbol ?? ""}
                {item?.allergy_tags.length == index + 1 ? "" : ","}
              </li>
            );
          })}
        </ul>
      );
    }
    return res;
  }

  function getItemDiv(item, itemKey, type) {
    let cartItemKey;
    if (type === "promotion") {
      cartItemKey =
        cart_detail && cart_detail.length > 0 && item?.id
          ? cart_detail.findIndex(
              (p) => p.id == item.id && p.promotions?.id == item.promotions?.id
            )
          : -1;
    } else {
      const checkAutoPay =
        promotionArr.length > 0 && item?.promotions?.id === undefined
          ? promotionArr.findIndex(
              (p) => p.id == item.id && p?.promotions?.auto_apply == "Yes"
            )
          : -1;

      if (checkAutoPay > -1) {
        item = promotionArr[checkAutoPay];
      }

      cartItemKey = qr_order
        ? cart_detail && cart_detail.length > 0 && item?.id
          ? cart_detail.findIndex((p) =>
              p?.promotions?.id
                ? p.id == item.id
                : // && p.promotions?.id == item.promotions?.id
                  p.id == item.id
            )
          : -1
        : cart_detail && cart_detail.length > 0 && item?.id
        ? cart_detail.findIndex((p) =>
            p?.promotions?.id
              ? p.id == item.id && p.promotions?.id == item.promotions?.id
              : p.id == item.id
          )
        : -1;
    }

    const activeCartItemKey =
      cart_detail && cart_detail.length > 0 && item?.id
        ? cart_detail.findIndex((p) =>
            p?.promotions?.id
              ? p.is_added == false &&
                p.id == item.id &&
                p.promotions?.id == item.promotions?.id
              : p.is_added == false && p.id == item.id
          )
        : -1;

    const submittedCartItemKey = qr_order
      ? cart_detail && cart_detail.length > 0 && item?.id
        ? cart_detail.findIndex((p) =>
            p?.promotions?.id
              ? p.is_added == true && p.id == item.id
              : // &&
                // p.promotions?.id == item.promotions?.id
                p.is_added == true && p.id == item.id
          )
        : -1
      : cart_detail && cart_detail.length > 0 && item?.id
      ? cart_detail.findIndex(
          (p) =>
            p?.promotions?.id
              ? (p.is_added == true && p.id == item.id) ||
                (p?.is_fired == true && p.id == item.id)
              : (p.is_added == true && p.id == item.id) ||
                (p?.is_fired == true && p.id == item.id)
          // ? p.is_added == true &&
          //   p?.is_fired == true &&
          //   p.id == item.id &&
          //   p.promotions?.id == item.promotions?.id
          // : p.is_added == true && p.id == item.id && p?.is_fired == true
        )
      : -1;

    const cartItemObj = cartItemKey !== -1 ? cart_detail?.[cartItemKey] : null;
    const activeDartItemObj =
      activeCartItemKey !== -1 ? cart_detail?.[activeCartItemKey] : null;

    let isCodeMatch = true;

    const loyaltyPromotion = table_detail?.Promotions?.filter(
      (p) => +p.loyalty_only_promotion
    );
    const nonLoyaltyPromotion = table_detail?.Promotions?.filter(
      (p) => !+p.loyalty_only_promotion
    );

    for (let i = 0; i < loyaltyPromotion.length; i++) {
      const isExist = loyaltyPromotion[i].MenuItem.find(
        (i) => i.id === item.id
      );
      if (isExist && loyaltyPromotion[i].loyalty_only_promotion) {
        isCodeMatch = eber_details?.redeemable_list?.find(
          (r) => r?.pos_redeem_sku === loyaltyPromotion[i]?.code
        );
        if (isCodeMatch) break;
      }
    }

    if (!isCodeMatch) {
      nonLoyaltyPromotion.map((promotion) => {
        const isExist = promotion.MenuItem.find((i) => i.id === item.id);
        if (isExist) isCodeMatch = true;
      });
    }

    // if(table_detail?.Promotions?.find((p)=>p.loyalty_only_promotion)){
    //   isCodeMatch= eber_details  &&

    //   eber_details?.redeemable_list?.find(
    //     (r) => r?.pos_redeem_sku === item?.promotions?.code
    //   );
    // }

    // const isCodeMatch = item?.promotions?.some((promo) =>
    //   eber_details?.redeemable_list?.some((r)=>promo?.code===r?.pos_redeem_sku)
    // );
    const isPromotion = item?.promotions?.id && isCodeMatch ? true : false;
    const checkAvailable =
      // item?.is_86_item == "false" &&
      !items86Arr.includes(item?.id) ? true : false;
    const checkValidImg = /\.(jpe?g|png|gif|bmp)$/i.test(item?.image);

    //                     ? item?.dinein_price?.DineInPostTax
    //                       ? parseFloat(
    //                           item?.dinein_price?.DineInPostTax
    //                         ).toFixed(2)
    //                       : "0.00"
    //                     : item?.dinein_price?.DineIn
    //                     ? parseFloat(item?.dinein_price?.DineIn).toFixed(2)
    //                     : "0.00")}

    return (
      <>
        {type === "promotion" ? (
          <div
            // onClick={() => {
            //   if (checkAvailable && !server_detail) {
            //     dispatch(getSelectedItemDetail(item));
            //     dispatch(emptyEditedItemDetail());
            //     if (item?.menu_item_type == "bundled") {
            //       // setShowBumdleItem(true)
            //     } else {
            //       // setShowAddItem(true)
            //     }
            //     // history.push("/bundle-add-item");
            //     // else history.push("/add-item");
            //   }
            // }}
            onClick={() => {
              if (checkAvailable && !server_detail) {
                dispatch(getSelectedItemDetail(item));
                dispatch(emptyEditedItemDetail());
                // if (item?.menu_item_type == "bundled")
                //   history.push("/bundle-add-item");
                // else history.push("/add-item");
                if (item?.menu_item_type == "bundled") {
                  addBundlePopUp();
                } else {
                  addItemPopUp();
                }
                // history.push("/bundle-add-item");
                // else history.push("/add-item");
              }
            }}
            className="card col-6 card-default-permotion py-3 "
          >
            <LazyLoadImage
              src={item?.image ? item?.image : table_detail?.Merchant?.logo}
              className="permotion-image w-100 "
              alt="..."
            />
            <h5 className="card-title mb-0 mt-1"> {item?.menu_item_name}</h5>
            <div className="d-flex label_row mt-1">
              <p
                className={`pricing ${
                  isPromotion ? "promotion-strike-off" : ""
                }`}
              >
                {" "}
                <small className="fw-bolder">{`${
                  table_detail?.currency ?? ""
                }`}</small>
                {isPromotion
                  ? showPostTaxPrice
                    ? item?.promotions?.accutalPostTaxPrice
                      ? parseFloat(
                          item?.promotions?.accutalPostTaxPrice
                        ).toFixed(2)
                      : "0.00"
                    : item?.promotions?.accutalPrice
                    ? parseFloat(item?.promotions?.accutalPrice).toFixed(2)
                    : "0.00"
                  : showPostTaxPrice
                  ? item?.dinein_price?.DineInPostTax
                    ? parseFloat(item?.dinein_price?.DineInPostTax).toFixed(2)
                    : "0.00"
                  : item?.dinein_price?.DineIn
                  ? parseFloat(item?.dinein_price?.DineIn).toFixed(2)
                  : "0.00"}
              </p>
              {isPromotion && (
                <p className="px-1 promotion-price">
                  <small className="fw-bolder">{`${
                    table_detail?.currency ?? ""
                  }`}</small>
                  {showPostTaxPrice
                    ? item?.dinein_price?.DineInPostTax
                      ? parseFloat(item?.dinein_price?.DineInPostTax).toFixed(2)
                      : "0.00"
                    : item?.dinein_price?.DineIn
                    ? parseFloat(item?.dinein_price?.DineIn).toFixed(2)
                    : "0.00"}
                </p>
              )}
            </div>
          </div>
        ) : (
          <div
            className={`menu-box Px-15
        ${
          submittedCartItemKey !== -1 && activeCartItemKey === -1
            ? " submitted"
            : ""
        }
        ${cartItemKey !== -1 ? " active" : ""}${
              checkAvailable === false ? " disabled" : ""
            }`}
            key={itemKey}
            id={`${type === "promotion" ? "promo" : "element"}_${
              item?.id !== undefined ? "item_id_" + item?.id : "null"
            }`}

            // style={{color:cartItemKey !== -1 ?  table_detail?.QrOrdering?.brand_main_color: "" }}
          >
            {/* {!server_detail && getItemTags(item)} */}
            <div
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => {
                if (checkAvailable && !server_detail) {
                  dispatch(getSelectedItemDetail(item));
                  dispatch(emptyEditedItemDetail());
                  // if (item?.menu_item_type == "bundled")
                  //   history.push("/bundle-add-item");
                  // else history.push("/add-item");
                  if (item?.menu_item_type == "bundled") {
                    addBundlePopUp();
                  } else {
                    addItemPopUp();
                  }
                  // history.push("/bundle-add-item");
                  // else history.push("/add-item");
                }
              }}
              className="row mx-0 align-items-start"
            >
              {item?.image && (
                <figure className="col-3 menu-img ">
                  <ImageTag
                    src={
                      item?.image ? item?.image : table_detail?.Merchant?.logo
                    }
                    defaultimage={table_detail?.Merchant?.logo}
                    removeImage={true}
                    removeCol={true}
                    replaceColName={"col-12"}
                    removeColName={"col-7"}
                  />
                </figure>
              )}

              <div
                className={`menu-content ${
                  item?.image ? "px-2 col-9" : "px-0 pr-2 col-12"
                } `}
                onClick={() => {
                  // alert("hello")
                  setItemDetails(item);
                  if (checkAvailable && server_detail) {
                    dispatch(getSelectedItemDetail(item));
                    dispatch(emptyEditedItemDetail());
                    if (item?.menu_item_type == "bundled")
                      history.push("/bundle-add-item");
                    else history.push("/add-item");
                  }
                }}
              >
                <span className="d-flex align-items-end mb-1">
                  <h4 className="menu-title text-wrap mb-0">
                    {!server_detail &&
                      item?.image &&
                      getItemTotalQuantity(
                        item,
                        type,
                        false,
                        submittedCartItemKey,
                        activeCartItemKey
                      )}{" "}
                    {item?.menu_item_name} {/* {item?.menu_item_type} */}
                    {!server_detail && getItemFilterTags(item)}
                  </h4>
                  {/* {!server_detail && getItemFilterTags(item)} */}
                </span>

                {!server_detail && (
                  <p className="item-description"> {item?.description}</p>
                )}
                <div className="label_row d-flex">
                  <p className={`pricing ${isPromotion ? "strike-off" : ""}`}>
                    {" "}
                    <small className="fw-bolder">{`${
                      table_detail?.currency ?? ""
                    }`}</small>
                    {isPromotion
                      ? showPostTaxPrice
                        ? item?.promotions?.accutalPostTaxPrice
                          ? parseFloat(
                              item?.promotions?.accutalPostTaxPrice
                            ).toFixed(2)
                          : "0.00"
                        : item?.promotions?.accutalPrice
                        ? parseFloat(item?.promotions?.accutalPrice).toFixed(2)
                        : "0.00"
                      : showPostTaxPrice
                      ? item?.dinein_price?.DineInPostTax
                        ? parseFloat(item?.dinein_price?.DineInPostTax).toFixed(
                            2
                          )
                        : "0.00"
                      : item?.dinein_price?.DineIn
                      ? parseFloat(item?.dinein_price?.DineIn).toFixed(2)
                      : "0.00"}
                  </p>
                  {isPromotion && (
                    <p className="px-0 promotion-price">
                      <small className="fw-bolder">{`${
                        table_detail?.currency ?? ""
                      }`}</small>
                      {showPostTaxPrice
                        ? item?.dinein_price?.DineInPostTax
                          ? parseFloat(
                              item?.dinein_price?.DineInPostTax
                            ).toFixed(2)
                          : "0.00"
                        : item?.dinein_price?.DineIn
                        ? parseFloat(item?.dinein_price?.DineIn).toFixed(2)
                        : "0.00"}
                    </p>
                  )}
                  {!server_detail && getItemTags(item)}
                </div>
              </div>
              {server_detail && activeDartItemObj && (
                <div className="col-4 px-0 text-right price-right">
                  <div className="items-sec items-sec-end">
                    <button
                      type={"button"}
                      className="remove-btn new-add-remove"
                      // disabled={activeDartItemObj.quantity <= 1}
                      onClick={() => decreaseItem(activeDartItemObj.offline_id)}
                      style={{ border: `none` }}
                    >
                      <StyledSVG
                        color={"#8b8b8b"}
                        src={
                          activeDartItemObj.quantity <= 1
                            ? removeIcon
                            : minusIcon
                        }
                        width="16"
                      />{" "}
                    </button>
                    <input
                      type="text"
                      className="items-num new-items-num"
                      value={activeDartItemObj.quantity}
                      style={{ color: "#5A5655" }}
                      readOnly
                    />
                    <button
                      type={"button"}
                      className="add-btn new-add-add"
                      onClick={() => increaseItemCheck(activeDartItemObj, item)}
                      style={{ border: `none` }}
                    >
                      <StyledSVG color={"#8b8b8b"} src={plusIcon} width="16" />{" "}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isUserLogin = () => {
    if (eber_details) {
      return true;
    } else if (como_details) {
      return true;
    } else if (xgateDetails) {
      return true;
    } else {
      return false;
    }
  };

  const errorMessageFor86Items = () => {
    const prefix_86_items = `Sincere apologies, the following item(s) you've added to your basket are no longer available and will be removed from your basket:`;
    return (
      <>
        {prefix_86_items}
        <p style={{ fontWeight: "bold" }}>
          {showQuantityErrMsg.replace(/,$/, ".")}
        </p>
      </>
    );
  };
  const tempFilter = () => {
    let filterArray = [];
    filterArray.push({ value: "Popular", label: "Popular", symbol: "P" });
    filterArray.push({ value: "Trending", label: "Trending", symbol: "T" });
    filter_tag_detail.map((data) => {
      filterArray.push({
        id: data?.id,
        value: data?.name,
        label: data?.name,
        symbol: data?.symbol,
      });
    });
    return filterArray;
  };

  const serverExit = () => {
    {
      if (getCartNewItemCount(cart_detail) >= 1) {
        setExitOption(true);
      } else if (
        getCartNewItemCount(cart_detail) == 0 &&
        localCartDetails.length > 0 &&
        localCartDetails.filter((p) => p.is_fired === false).length > 0
      ) {
        orderPlaceHandler(false, totalTip, false, false, "no");
        exit();
      } else {
        exit();
      }
      dispatch(saveServerOrderId(""));
    }
  };

  const catrgories_data =
    table_detail?.SubCategory &&
    table_detail?.SubCategory.filter(
      (SubCat) =>
        filterSubCatTableDetails(SubCat) &&
        checkSubCatAvailable(SubCat, table_detail)
    );

  const [hideHeader, setHideHeader] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [callServer, setCallServer] = useState(false);

  const callServerAction = (action) => {
    dispatch(acknowledgeCallServerData(false));
    const payload = {
      operation: action ? "call" : "cancel", //cancel : call
      merchant_id: table_detail?.PosTableMaster?.merchant_id,
      customer_id: user_detail?.id,
      pos_order_id: recent_order_id,
      table_no: table_detail?.PosTableMaster?.table_number,
      call_id: callServerData?.id,
    };

    dispatch(updateCallServer(payload));
    action ? setCallServer(true) : setCallServer(false);
  };

  useEffect(() => {
    console.log("useEffact-scrolling=");

    document.getElementsByTagName("html")[0].style.overflow = "auto";
    const headings = document.querySelectorAll(".categorie-title");
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setHideHeader(true);
      } else {
        setHideHeader(false);
      }

      const scrollPosition = window.scrollY + 130;
      headings.forEach((heading, index) => {
        const offsetTop = heading.offsetTop;
        const offsetBottom = offsetTop + heading.clientHeight;

        if (scrollPosition >= offsetTop && scrollPosition < offsetBottom) {
          const divElement = document.getElementById(heading.id);
          const h3Element = divElement.querySelector("h3");
          const h3Content = h3Element.textContent;
          setActiveMenu(h3Content);
        }
      });
      // const scrollElement = document.querySelector(`.scroll-elememt`); // Replace with your selector

      // const { scrollTop, scrollHeight } = scrollElement;
      // const progress = scrollTop / (scrollHeight - window.innerHeight);
      // scrollElement.style.transform = `translateY(${progress * 100}vh)`; // Slow scroll based on progress (0-1)
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [table_detail]);
  const [originalHeight, setOriginalHeight] = useState(0);

  const changeModalStyle = () => {
    document.getElementsByTagName("html")[0].style.overflow = "auto";
    if (showAddItem) {
      const element = document.getElementById(`popdown-content-addItem`);
      element.classList.remove("popup-content");
      element.classList.add("popdown-content");
      setTimeout(() => {
        setShowAddItem(false);
      }, 300);
    } else {
      const element_bundle = document.getElementById(
        `popdown-content-bundleItem`
      );
      element_bundle.classList.remove("popup-content");
      element_bundle.classList.add("popdown-content");
      setTimeout(() => {
        setShowBumdleItem(false);
      }, 300);
    }
  };
  const [isOpen, seIsOpen] = useState(false);

  const scrollView = (id) => {
    const element = document.getElementById(id);
    window?.scrollTo({
      top: element.offsetTop - 105,
      behavior: "smooth",
    });
  };

  const reOrderDiv = (reorderArr) => {
    return reorderArr?.map((recomItem, recomIdx) => {
      // const permotionID = promotionArr.findIndex(recomItem?.id)
      const permotionID =
        promotionArr.length > 0 && recomItem?.promotions?.id === undefined
          ? promotionArr.findIndex(
              (p) => p.id == recomItem.id && p?.promotions?.auto_apply == "Yes"
            )
          : -1;

      if (permotionID > -1) {
        recomItem = promotionArr[permotionID];
      }
      const itemIds = table_detail?.SubCategory.filter(
        (SubCat) =>
          filterSubCatTableDetails(SubCat) &&
          checkSubCatAvailable(SubCat, table_detail)
      ).flatMap((SubCat) => SubCat.MenuItem.map((item) => item.id));
      if (itemIds.includes(recomItem.id)) {
        return (
          <div className="swiper-slide">
            <div className="box recomItem ">
              <div className="title_row justify-content-between">
                <div className="item_title ">
                  {/* <span>
                x{recomItem?.recomItem?.quantity ?? "1"}
              </span>{" "} */}
                  <text>{recomItem?.menu_item_name}</text>
                </div>
                <p className="promotion-price">
                  <small className="fw-bolder">{`${
                    table_detail?.currency ?? ""
                  }`}</small>
                  {showPostTaxPrice
                    ? parseFloat(
                        recomItem?.dinein_price?.DineInPostTax
                      ).toFixed(2)
                    : parseFloat(recomItem?.dinein_price?.DineIn).toFixed(2)}
                </p>
              </div>

              <div className="date_row mt-0">
                <p className="date">
                  {moment(recomItem?.recomItem?.created_on).format(
                    "dddd, MMM Do"
                  ) ?? ""}
                </p>
                <p
                  className="reorder d-flex align-items-center"
                  onClick={() => {
                    // Check if the item exists in the filtered SubCategory MenuItems

                    if (itemIds.includes(recomItem.id)) {
                      // Check if the item is available based on its type
                      const checkPeriodAvailable =
                        recomItem?.menu_item_type === "bundled"
                          ? true
                          : checkItemAvailable(
                              recomItem,
                              table_detail?.Merchant?.timezone_offset ??
                                "00:00",
                              table_detail
                            );

                      // Check if the item is not in items86Arr
                      const checkAvailable = !items86Arr.includes(
                        recomItem?.id
                      );

                      if (checkAvailable && checkPeriodAvailable) {
                        // Dispatch actions based on the item type
                        dispatch(getSelectedItemDetail(recomItem));
                        dispatch(emptyEditedItemDetail());

                        if (recomItem?.menu_item_type === "bundled") {
                          addBundlePopUp();
                        } else {
                          addItemPopUp();
                        }
                      }
                    }
                  }}
                  // onClick={() => {
                  //   const checkPeriodAvailable =
                  //     recomItem?.menu_item_type == "bundled"
                  //       ? true
                  //       : checkItemAvailable(
                  //           recomItem,
                  //           table_detail?.Merchant
                  //             ?.timezone_offset ?? "00:00",
                  //           table_detail
                  //         );
                  //   const checkAvailable =
                  //     // recomItem?.is_86_item == "false" &&
                  //     !items86Arr.includes(recomItem?.id)
                  //       ? true
                  //       : false;
                  //   if (
                  //     checkAvailable &&
                  //     checkPeriodAvailable
                  //   ) {
                  //     dispatch(
                  //       getSelectedItemDetail(recomItem)
                  //     );
                  //     dispatch(emptyEditedItemDetail());
                  //     if (
                  //       recomItem?.menu_item_type == "bundled"
                  //     )
                  //       history.push("/bundle-add-item");
                  //     else history.push("/add-item");
                  //   }
                  // }}
                  checkPeriodAvailable
                >
                  <StyledSVG
                    src={refresh}
                    color={table_detail?.QrOrdering?.brand_main_color}
                    width={20}
                    height={20}
                  />
                  <span className="ml-1">Re-order</span>
                </p>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  function getHomePage() {
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <>
          <div
            className={`wrapper custom_pb default-bg
           
          `}
          >
            <img
              className="background-image"
              id="header-background-img"
              alt=""
              src={table_detail?.QrOrdering?.hero_image}
            />
            <section className="middle-sec ">
              <div className="sticky-header">
                <>
                  <div>
                    <div className={`container ${hideHeader ? "hidden" : ""}`}>
                      <div className={`container image-border landing-header`}>
                        <div className="row align-items-center ">
                          <div
                            className="col-4 mx-0 px-0 Px-15"
                            onClick={() => {
                              callServerData?.id
                                ? setCallServer(true)
                                : callServerAction(true);
                            }}
                          >
                            {table_detail?.QrOrdering?.enable_call_server ==
                              "yes" && (
                              <span className="d-flex align-items-center">
                                <StyledSVG
                                  color={
                                    table_detail?.QrOrdering
                                      ?.brand_main_color || "#8b8b8b"
                                  }
                                  className="mr-2"
                                  src={handWave}
                                  width={20}
                                />
                                <p className="appheader-call">Call Server</p>
                              </span>
                            )}
                          </div>
                          <div className="col-4 ">
                            <div className="bg-white translate-image">
                              <img
                                className="logo mx-auto d-block"
                                src={table_detail?.Merchant?.logo}
                                alt=""
                              />
                            </div>
                          </div>
                          {
                            <div className="col-4 px-0  mx-0 Px-15 ">
                              <p className="text-end table-name">
                                {"Table"}{" "}
                                <b>
                                  {table_detail?.PosTableMaster?.table_number}
                                </b>
                              </p>
                            </div>
                          }
                        </div>
                      </div>
                      <span className="merchant-title d-flex  justify-content-center bg-white ">
                        {table_detail?.Merchant?.account_name}
                      </span>
                    </div>

                    <div
                      className={`container px-0 ${
                        !hideHeader ? "hidden" : ""
                      }`}
                    >
                      <div
                        className={`bg-white d-flex justify-content-between align-items-end Px-15 pt-3`}
                      >
                        <span className="merchant-title d-flex justify-content-center position-relative">
                          {table_detail?.Merchant?.account_name}
                        </span>
                        <span className="d-flex align-items-center  position-relative">
                          {table_detail?.QrOrdering?.enable_call_server ==
                            "yes" && (
                            <StyledSVG
                              color={
                                table_detail?.QrOrdering?.brand_main_color ||
                                "#8b8b8b"
                              }
                              style={{ left: "-30px" }}
                              className="mr-2 position-absolute "
                              src={handWave}
                              width={20}
                              onClick={() => {
                                callServerData?.id
                                  ? setCallServer(true)
                                  : callServerAction(true);
                              }}
                            />
                          )}
                          <p className="text-end table-name">
                            {"Table"}{" "}
                            <b>{table_detail?.PosTableMaster?.table_number}</b>
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </>

                <div
                  className={`filter_row menus_row${
                    server_detail ? " p-0" : ""
                  }`}
                >
                  <div className=" rm-overflow row w-100 mx-0 pt-4 mb-3">
                    <div
                      style={{ zIndex: 99999999 }}
                      className="d-flex position-absolute  w-100 justify-content-between"
                    >
                      <div
                        className="border border-dark bg-white"
                        style={{
                          height:
                            dpStates.categories && dpStates?.isMenuVisible
                              ? "480px"
                              : "50px",
                          width: dpStates.categories ? "75%" : "20%",
                          transition:
                            "width 0.3s ease, height 0.3s ease, border-radius 0.3s ease",
                          borderRadius: dpStates.categories ? "26px" : "25px",
                        }}
                      >
                        {getCategoriesView()}
                      </div>
                      <div
                        className="border border-dark bg-white"
                        style={{
                          height:
                            dpStates.search && dpStates?.isMenuVisible
                              ? "480px"
                              : "50px",
                          width: dpStates.search ? "75%" : "20%",
                          transition:
                            "width 0.3s ease, height 0.3s ease, border-radius 0.3s ease",
                          borderRadius: dpStates.search ? "26px" : "25px",
                        }}
                      >
                        {getSearchView()}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="position-relative"
                  style={{ height: "50px" }}
                ></div>
              </div>

              <div
                className="menu-sections"
                // id="menu-sections"
                // onScroll={scrolledElement}
                // {...handlers}
              >
                <div className="carousel-home mb-3">
                  <Carousel
                    showThumbs={false}
                    showIndicators={false}
                    showArrows={false}
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={true}
                  >
                    {table_detail?.QrPromotionalImage?.map((data, index) => {
                      return (
                        <div className="carousel-body mr-2">
                          <LazyLoadImage
                            style={{ height: "200px" }}
                            src={data?.file_name}
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                </div>

                {checkArray(promotionDisplayArr) &&
                  filterPromoCatTableDetails() && (
                    <div
                      name={"promotion_section"}
                      id={"promotion_section_con"}
                    >
                      <h3 className="title letter-spacing"> Promotions</h3>

                      <div className="row mx-0 bg-white">
                        {showMorePermotion
                          ? promotionDisplayArr.map(function (item, itemKey) {
                              return getItemDiv(item, itemKey, "promotion");
                            })
                          : promotionDisplayArr
                              .slice(0, 2)
                              .map(function (item, itemKey) {
                                return getItemDiv(item, itemKey, "promotion");
                              })}
                        {showMorePermotion ? (
                          <span
                            className={"view-all-condition p-3"}
                            onClick={() => setShowMorePermotion(false)}
                          >
                            Show less
                          </span>
                        ) : (
                          <span
                            className={"view-all-condition p-3"}
                            onClick={() => setShowMorePermotion(true)}
                          >
                            Show more
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                {!server_detail && reorderArr?.length > 0 && (
                  <div name={"recommended_section"}>
                    <div className="recommended_section mt-2">
                      <h3 className="title letter-spacing ">Order Again</h3>
                      <div className="recommended_slider">
                        {reOrderDiv(reorderArr)}
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-3 scroll-elememt">
                  {table_detail?.SubCategory.filter(
                    (SubCat) =>
                      filterSubCatTableDetails(SubCat) &&
                      checkSubCatAvailable(SubCat, table_detail)
                  ).map(function (subCat, index) {
                    const menuItem = subCat?.MenuItem ?? undefined;
                    return (
                      <div
                        key={index}
                        className={`${
                          index ===
                          table_detail?.SubCategory?.filter(
                            (SubCatt) =>
                              filterSubCatTableDetails(SubCatt) &&
                              checkSubCatAvailable(SubCatt, table_detail)
                            // && checkTrendingAndPopularApplied(p)
                          ).length -
                            1
                            ? "last-menu-items"
                            : ""
                        } categorie-title`}
                        id={subCat?.name ? "#sub_id_" + subCat?.id : "null"}
                      >
                        <h3
                          title={subCat?.name}
                          className="title  letter-spacing"
                        >
                          {" "}
                          {subCat?.name}
                        </h3>
                        {subCat?.description && subCat?.description !== "" && (
                          <p className=" bold mb-0 pb-4 desSec subCat-home letter-spacing Px-15">
                            {subCat?.description ?? ""}
                          </p>
                        )}

                        {menuItem.length > 0 &&
                          menuItem
                            .filter(
                              (item) =>
                                filterItemTableDetails(subCat, item) &&
                                checkItemAvailable(
                                  item,
                                  table_detail?.Merchant?.timezone_offset ??
                                    "00:00",
                                  table_detail
                                ) &&
                                checkTrendingAndPopularApplied(item, false)
                            )
                            .map(function (item, itemKey) {
                              return getItemDiv(item, itemKey, "menuItem");
                            })}
                      </div>
                    );
                  })}
                  {/* </InfiniteScroll> */}
                </div>
              </div>
            </section>

            <section
              className={`bottom-sec ${
                cart_detail?.length <= 0 ? "disabled" : ""
              }`}
            >
              <div className="container Px-15">
                <div className="row mx-0">
                  <div
                    className={
                      qr_order || showPayPopUp
                        ? "col-3 pr-1 pl-0"
                        : "display_Hidden"
                    }
                  >
                    <CustomButton
                      color={table_detail?.QrOrdering?.brand_main_color}
                      title={"Pay"}
                      className={`cart-button-loyality shadow  ${
                        cart_detail?.length <= 0 ? "disabled" : ""
                      }`}
                      onClick={() => {
                        document.getElementsByTagName(
                          "html"
                        )[0].style.overflow = "hidden";
                        qr_order
                          ? serverExit()
                          : showPayPopUp && dispatch(quantityCheckedDetails());
                      }}
                    />
                  </div>
                  <div
                    className={
                      !loyaltyEnabled
                        ? "display_Hidden"
                        : qr_order || showPayPopUp
                        ? "col-4 px-1"
                        : "col-6 pr-2 pl-0"
                    }
                  >
                    <CustomButton
                      icon={loyaltyHeart}
                      color={table_detail?.QrOrdering?.brand_main_color}
                      title={"Loyalty"}
                      className={`cart-button-loyality shadow  ${
                        cart_detail?.length <= 0 ? "disabled" : ""
                      }`}
                      onClick={() => {
                        if (isUserLogin()) {
                          setShowAccountPopUp(1);
                        } else {
                          dispatch(emptyUserDetail());
                          // history.push("/login");
                        }
                      }}
                    />
                  </div>
                  <div
                    className={
                      qr_order || showPayPopUp
                        ? !loyaltyEnabled
                          ? "col-9  pl-2 pr-0"
                          : "col-5 px-1"
                        : !loyaltyEnabled
                        ? "col-12  pl-2 pr-0"
                        : "col-6  pl-2 pr-0"
                    }
                  >
                    <CustomButton
                      disabled={cart_detail?.length == 0}
                      onClick={() => {
                        if (cart_detail?.length > 0) {
                          toggleCartPopUp();
                          document.getElementsByTagName(
                            "html"
                          )[0].style.overflow = "hidden";
                        }
                      }}
                      color={"white"}
                      title={`Basket (${cartCount})`}
                      icon={shoppingcart}
                      className={`${
                        cart_detail?.length == 0 ? "opacity-50" : "opacity-100"
                      } cart-button shadow `}
                    />
                  </div>
                </div>
              </div>
            </section>
            {callServer && (
              <div className="">
                <div
                  className="callServer-modal"
                  onClick={() => {
                    setCallServer(false);
                    dispatch(acknowledgeCallServerData(false));
                  }}
                ></div>
                <div className="modal-content-callserver  modal-fixed">
                  <div className="position-absolute close-callServer">
                    <StyledSVG
                      onClick={() => {
                        setCallServer(false);
                        dispatch(acknowledgeCallServerData(false));
                      }}
                      src={close_black}
                      height={20}
                      width={20}
                    />
                  </div>
                  <div className="text-center">
                    <img src={waveGif} style={{ maxWidth: "85px" }}></img>
                    <p
                      disabled={callServerData?.operation == "call"}
                      className="server-notified mt-2"
                    >
                      {acknowledge ? "Server En Route" : "Server Notified"}
                    </p>

                    {!acknowledge && (
                      <p
                        disabled={callServerData?.operation == "cancel"}
                        className="cancle-request mt-2"
                        onClick={() => callServerAction(false)}
                      >
                        Cancel Request
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className={`filter_modal ${showFilterCon ? "show" : ""}`}>
              <div className="modal_header">
                <div className="filter_icon">
                  <ImageTag src={filterImg} />
                  {/* {countActiveFilter()} */}
                </div>
                <h4>Filters</h4>
                <div className="close">
                  <ImageTag
                    src={close_dark}
                    onClick={() => {
                      setApplyFilters(filters);
                      setShowFilterCon(false);
                    }}
                  />
                </div>
              </div>
              <div className="modal_body">
                <h5 className="title">By Tags</h5>
                {getFilterModalTags()}
                <button
                  className="apply_btn"
                  disabled={filters === applyFilters}
                  onClick={() => {
                    setShowFilterCon(false);
                    dispatch(
                      getTableDetailByFilter(id, applyFilters, applySearch)
                    );
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
            {showCartPage === false && (
              <AccountPopup
                show={showAccountPopUp}
                onCancel={() => setShowAccountPopUp(0)}
                addBundlePopUp={addBundlePopUp}
                addItemPopUp={addItemPopUp}
              />
            )}
            <Modal
              show={show_table_already_inuse_modal}
              title={
                server_detail
                  ? "There is another server device currently ordering for this table, do you wish to continue?"
                  : "There is another device currently ordering for this table, do you wish to create a separate order in addition to that device's order?"
              }
              onCancel={() => {
                if (server_detail?.CurrentRoom) {
                  history.push(
                    `/get_server_details/${server_detail?.CurrentRoom.merchant_id}/${server_detail.CurrentRoom.server_code}`
                  );
                } else {
                  dispatch(emptyUserDetail());
                }
              }}
              OnSubmit={() => dispatch(emptyChangeTableAlreadyInuse())}
              cancelBtnText="No"
              submitBtnText="Yes"
            />
            <Modal
              show={addMultipeItem}
              title={"Do you want to customized the menu?"}
              onCancel={() => {
                increaseItem();
                dispatch(addSameProductCheck(false));
              }}
              OnSubmit={() => {
                const items86Arr = checkArray(items_86_detail)
                  ? pluckArray(items_86_detail, "id")
                  : [];
                const checkAvailable = !items86Arr.includes(
                  selected_item_detail?.id
                )
                  ? true
                  : false;
                if (checkAvailable && server_detail) {
                  dispatch(getSelectedItemDetail(selected_item_detail));
                  dispatch(emptyEditedItemDetail());
                  if (selected_item_detail?.menu_item_type == "bundled")
                    history.push("/bundle-add-item");
                  else history.push("/add-item");
                }
                dispatch(addSameProductCheck(false));
              }}
              cancelBtnText="No"
              submitBtnText="Yes"
            />

            <Modal
              show={showQuantityErr}
              title={errorMessageFor86Items()}
              OnSubmit={() => {
                dispatch(remove_86_ItemCartDetail());
                dispatch(quantityModalClose());
              }}
              submitBtnText="Ok"
            />

            {/* <Modal
              show={callServer}
              modalbody={
                <div className="callServer">
                  <StyledSVG
                    onClick={() => setCallServer(false)}
                    src={close_black}
                    height={20}
                    width={20}
                  />
                   <img src={waveGif} style={{ maxWidth: "85px" }}></img>
                  <p className="server-notified">Server Notified</p>

                  <p
                    className="cancle-request"
                    onClick={() => setCallServer(false)}
                  >
                    Cancel Request
                  </p>
                </div>
              }
            /> */}
            <Modal
              show={exitOption}
              title={
                "Do you want to keep the unsubmitted items in your basket for later submission?"
              }
              onCancel={() => {
                orderPlaceHandler(false, totalTip, false, false, "no");
                setExitOption(false);
                exit();
              }}
              OnSubmit={() => {
                orderPlaceHandler(false, totalTip, false, false, "yes");
                setExitOption(false);
                exit();
              }}
              cancelBtnText="No"
              submitBtnText="Yes"
            />
            <Motion
              defaultStyle={{ x: 100 }}
              style={{
                x: spring(showCartPage === true ? 0 : 100, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => {
                return (
                  <Cart
                    style={{
                      right: `-${x}%`,
                    }}
                    setShowCartPage={toggleCartPopUp}
                    slidePaymentPage={slidePaymentPage}
                    showPaymentPage={showPaymentPage}
                    slidePayConfirmPage={slidePayConfirmPage}
                    showPayConfirmPage={showPayConfirmPage}
                    cartBgWhite={cartBgWhite}
                    togglePaymentPopUp={togglePaymentPopUp}
                    togglePayConfirmPopUp={togglePayConfirmPopUp}
                    totalCartAmount={totalCartAmount}
                    totalServiceCharge={totalServiceCharge}
                    totalItemLevelDiscount={totalItemLevelDiscount}
                    totalSaleTax={totalSaleTax}
                    totalDiscount={totalDiscount}
                    promoDiscount={promoDiscount}
                    totalTip={totalTip}
                    getCartBasketTotalAmount={getCartBasketTotalAmount}
                    setShowAccountPopUp={setShowAccountPopUp}
                    showAccountPopUp={showAccountPopUp}
                    showPromocodePopup={showPromocodePopup}
                    setShowPromocodePopup={setShowPromocodePopup}
                    submitOrderHandler={submitOrderHandler}
                    tipChangeHandler={tipChangeHandler}
                    showTipPopup={showTipPopup}
                    setShowTipPopup={setShowTipPopup}
                    promotionArr={promotionArr}
                    addBundlePopUp={addBundlePopUp}
                    addItemPopUp={addItemPopUp}
                  />
                );
              }}
            </Motion>
          </div>
          {showAddItem && (
            <div className={showAddItem ? "popup" : "testpopUp"}>
              <div id="popdown-content-addItem" className={"popup-content overflow-h"}>
                <AddItem
                  changeModalStyle={changeModalStyle}
                  showAddItem={showAddItem}
                />
              </div>
            </div>
          )}
          {showBumdleItem && (
            <div className={showBumdleItem ? "popup" : "testpopUp"}>
              <div
                id={"popdown-content-bundleItem"}
                className={`popup-content`}
              >
                <BundleAddItem
                  changeModalStyle={changeModalStyle}
                  showBumdleItem={showBumdleItem}
                />
              </div>
            </div>
          )}
        </>
      );
    } else if (loading === false) {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <PleaseWait />
        </div>
      );
    }
  }

  return (
    <>
      {/* <div style={{maxHeight:"100vh" ,overflow:"scroll"}}> */}
      {table_detail ? getHomePage() : null}
      {/* </div> */}
    </>
  );
}

export default Home;
