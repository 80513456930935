import React, { Suspense } from "react";

// Components

// Screens

// CSS
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CSS/style.css";
import "./CSS/responsive.css";

import Main from "./Routing";
import {SocketProvider} from "./contexts/socketContext";

function App() {
  return (
    <SocketProvider>
      <Main />
     </SocketProvider>
  );
}

export default App;
