import React, { createContext, useCallback, useEffect, useState } from "react";
import { io } from "socket.io-client";

let socketUrl = process?.env?.REACT_APP_SOCKET_URL;

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  console.log(children)

  const connectSocket = useCallback(
    ({ connectionPayload }) => {
      if (!socket) {
        const newSocketIntance = io(`${socketUrl}`, {
          transports: ["websocket", "polling"],
          withCredentials: true,
          query: connectionPayload,
        });

        setSocket(newSocketIntance);
      }
    },
    [socket]
  );

  const disconnectSocket = useCallback(() => {
    
    if (socket) {
      socket.disconnect();
      setSocket(null);
      setIsConnected(false)
    }

  }, [socket]);

  
   // Cleanup Function on unmount

  useEffect(() => {
    return () => {
      disconnectSocket();
    };
  }, [disconnectSocket]);

  return (
    <SocketContext.Provider
      value={{ socket, isConnected, connectSocket, disconnectSocket }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContext;
